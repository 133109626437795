$font-family-Regular: 'Poppins-Regular';
.root {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
  width: 100%;
  font-family: $font-family-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232323;
  &:global(.ant-form-item) {
    margin-bottom: 20px;
    @media (max-width: 1024px){
      margin-bottom: 10px;
    }
  }
  
  &.inputVerticalWrapper {
    flex-direction: column;
    &.ant-row {
      flex-direction: column;
    }
    .ant-form-item-label {
      text-align: left !important;
    }
  }

  .inputClass {
    border: 1px solid #130f40;
    border-radius: 7px;
    box-shadow: none !important;
    // width: 400px;
    max-width: 100%;
  }

  .messCls {
    color: #232323;
    font-family: $font-family-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
  }
}

// :global .ant-form-item-control-input-content{
//   &:has(.ant-input){
//     flex: none;
//   }
// }