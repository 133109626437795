$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 20px;
  gap: 10px;
  @media (max-width: 1024px){
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.searchMain {
  display: flex;
  width: 70%;
  flex-basis: 70%;
  @media (max-width: 1280px){
    flex-basis: 60%;
    width: 60%;
  }
  @media (max-width: 1024px){
    flex-basis: 100%;
    width: 100%;
  }
}
.search {
  border-radius: 8px;

  display: flex;
  border-width: 1px;
  border-color: black;
  height: 45px;
}
.searchText {
  font-size: medium;
  display: flex;
  font-weight: bold;
  margin-right: 10px;
}

.sub_main {
  display: flex;
  margin-top: -20px;
  width: 30%;
  flex-basis: 30%;
  justify-content: flex-end;
  @media (max-width: 1280px){
    flex-basis: 40%;
    width: 40%;
  }
  @media (max-width: 1024px){
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.dropdownStyle {
  width: 150px;
  margin-left: 10px;
  border-radius: 5px !important;
  border-color: black !important;

  // margin-bottom: 20px;
}
.filterStyle {
  font-size: medium;
  display: flex;
  line-height: 45px;
}

.filterButton{
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 45px !important;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  margin-left: 10px;

}

:global .ant-modal-wrap{
  width: 100% !important;
}