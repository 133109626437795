$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
@use '../../../assets/scss/common' as *;

.mainContainer {
  min-height: 776px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  @include contentHeight;

  .modalContainer {
    display: flex;
    width: 100%;
    max-width: 1266px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (max-width: 1440px){
      width: 90%;
    }
    @media (max-width: 1024px){
      width: 100%;
      max-width: 656px;
    }
    @media (max-width: 768px){
      width: 90%;
    }
    @media (max-width: 500px) {
     box-shadow: none;
    }
  }

  .subHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 30px;
    font-family: $font-family-Regular;
  }

  .questionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    .detailContainer {
      display: flex;
      flex-direction: column;
      .number1 {
        margin-left: 80px;
        font-family: $font-family-Dark;
        font-size: 130px;
        color: #130f40;
      }

      .number2 {
        font-family: $font-family-Dark;
        font-size: 130px;
        line-height: 30px;
        color: #130f40;

        .signContainer {
          font-size: 100px;
          padding-right: 15px;
        }
      }

      .answer {
        margin-top: 25px;

        hr {
          border: 2px solid #130f40;
          width: 90%;
        }

        :global(.Input_inputWrapper__faV4N .Input_inputClass__dfqtd) {
          margin-left: 40px;
          border: 1px solid #130f40;
          border-radius: 1px;
          width: 120px;
          padding: 2px;
          height: 120px;
          font-family: $font-family-Dark;
          font-size: 110px;
          text-align: right;
          color: #130f40;
          cursor: pointer;
        }
      }
    }
  }
}
