$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
.modalHeader {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  flex-wrap: wrap;

  .cogPlayIconQuiz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20%;
    color: black;
    font-family: $font-family-Light;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    @media (max-width: 700px){
      img{
        width: 50px;
      }
    }
  }

  .headerText {
    font-family: $font-family-Dark;
    color: #130f40;
    margin-right: 55px;
    font-size: 30px;
    @media (max-width: 1250px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      margin-right: 45px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      span{
        max-width: 250px;
        text-align: center;
        display: block;
      }
    }
    @media (max-width: 500px){
      span{
        max-width: unset;
      }
    }
  }
  .closeButton {
    cursor: pointer;
    :global(.ant-btn) {
      pointer-events: none;
      background: #5858fe;
      border-radius: 10px;
      font-family: $font-family-Dark;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 20px;
      height: 40px;
      @media (max-width: 650px) {
        width: 90%;
      }

      &:hover,
      &:focus,
      &:active {
        background: #5858fe;
        color: #ffffff;
      }
    }
  }
  .closeIcon{
    @media (max-width: 768px){
      svg{
        height: 16px;
      }
    }
  }
}
@media (max-width: 500px) {
  .modalHeader {
    display: block;
    padding-top: 0;
    .headerText {
      margin-right: 0px;
    }
    .cogPlayIconQuiz {
      display: none;
    }
    .closeButton{
      max-width: 200px;
      margin: auto;
      .closeIcon{
        display: none;
      }
    }
  }
}
