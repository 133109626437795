.cogPlayIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20%;
  color: #ffffff;
  font-family: 'Poppins-Light';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
}
