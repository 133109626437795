$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  padding: 35px;
  height: calc(100vh - 35px);
  overflow: auto;

  @media (max-width: 768px){
    padding: 35px 25px;
  }

  @media (max-width: 768px) and (min-height: 900px){
    justify-content: center;
  }

  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 100%;
    max-width: 1266px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (max-width: 1440px){
      width: 90%;
    }
    @media (max-width: 1024px){
      width: 100%;
      max-width: 656px;
    }
    @media (max-width: 768px){
      width: 90%;
    }
    @media (max-width: 600px){
      width: 95%;
    }
    @media (min-width: 200px) and (max-width: 500px) {
      box-shadow: none;
      width: 100%;
    }

    .instruction {
      width: 65%;
      text-align: center;
      font-family: $font-family;
      font-size: 22px;
      @media (max-width: 1250px) {
        font-size: 20px;
        width: 70%;
      }
      @media (max-width: 500px) {
        font-size: 16px;
        width: 70%;
      }
    }

    .redQuizWrapper {
      border: 15px solid #ff0500;
      border-radius: 30px;
      padding: 15px;
      @media (max-width: 500px){
        max-width: 318px;
      }
      .quizBorder {
        border: 2px dotted #ff0500;
        width: 300px;
        border-radius: 30px;
        @media (max-width: 500px){
          max-width: 318px;
          width: unset;
        }
        .upperArrow {
          img {
            margin-top: -38px;
          }
        }
        .quizContainer {
          display: flex;
          .imageWrapper {
            padding: 10px;
            margin-left: -25px;
            margin-top: 120px;
          }
          .questionWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .number1 {
              font-family: $font-family-Dark;
              font-size: 90px;
              color: #5858fe;
            }

            .number2 {
              font-family: $font-family-Dark;
              font-size: 90px;
              line-height: 30px;
              color: #5858fe;
              margin-right: 60px;
              .signContainer {
                font-size: 90px;
              }
            }

            .answer {
              margin-top: 25px;
              :global(.inputNumber_inputWrapper__XSSqQ .inputNumber_inputClass__iRG5R) {
                color: #ff1616;
              }
              hr {
                border: 2px solid #5858fe;
                width: 90%;
              }

              :global(.inputNumber_inputWrapper__Z0EY2 .inputNumber_inputClass__NjiWk) {
                :global(.ant-input-number-input-wrap) {
                  :global(input) {
                    border-radius: 5px;
                    padding: 2px;
                    height: 100px;
                    font-family: $font-family-Dark;
                    font-size: 90px;
                    text-align: center;
                    color: #ff0500;
                    cursor: pointer;
                  }
                }
              }
            }
            .correctAnswer {
              margin-top: 25px;
              :global(.inputNumber_inputWrapper__XSSqQ .inputNumber_inputClass__iRG5R) {
                color: #90ee90;
              }
              hr {
                border: 2px solid #5858fe;
                width: 90%;
              }

              :global(.inputNumber_inputWrapper__Z0EY2 .inputNumber_inputClass__NjiWk) {
                :global(.ant-input-number-input-wrap) {
                  :global(input) {
                    border-radius: 5px;
                    padding: 2px;
                    height: 100px;
                    font-family: $font-family-Dark;
                    font-size: 90px;
                    text-align: center;
                    color: green;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .downArrow {
          margin-top: 10px;
          img {
            transform: rotate(180deg);
            margin-bottom: -28px;
          }
        }
      }
    }

    .blueQuizWrapper {
      border: 15px solid #055ced;
      border-radius: 30px;
      padding: 15px;
      .quizBorder {
        border: 2px dotted #055ced;
        width: 300px;
        border-radius: 30px;
        .upperArrow {
          img {
            margin-top: -38px;
          }
        }
        .quizContainer {
          display: flex;
          .imageWrapper {
            margin-top: 130px;
            width: 32%;
          }
          .questionWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 70%;
            .number1 {
              font-family: $font-family-Dark;
              font-size: 90px;
              color: #5858fe;
            }

            .number2 {
              font-family: $font-family-Dark;
              font-size: 90px;
              line-height: 30px;
              color: #5858fe;
              margin-right: 60px;
              .signContainer {
                font-size: 90px;
              }
            }

            .answer {
              margin-top: 25px;
              :global(.inputNumber_inputWrapper__XSSqQ .inputNumber_inputClass__iRG5R) {
                color: #ff1616;
              }
              hr {
                border: 2px solid #5858fe;
                width: 90%;
              }

              :global(.inputNumber_inputWrapper__Z0EY2 .inputNumber_inputClass__NjiWk) {
                :global(.ant-input-number-input-wrap) {
                  :global(input) {
                    border-radius: 5px;
                    padding: 2px;
                    height: 100px;
                    font-family: $font-family-Dark;
                    font-size: 90px;
                    text-align: center;
                    color: #ff0500;
                    cursor: pointer;
                  }
                }
              }
            }
            .correctAnswer {
              margin-top: 25px;
              :global(.inputNumber_inputWrapper__XSSqQ .inputNumber_inputClass__iRG5R) {
                color: green;
              }
              hr {
                border: 2px solid #5858fe;
                width: 90%;
              }

              :global(.inputNumber_inputWrapper__Z0EY2 .inputNumber_inputClass__NjiWk) {
                :global(.ant-input-number-input-wrap) {
                  :global(input) {
                    border-radius: 5px;
                    padding: 2px;
                    height: 100px;
                    font-family: $font-family-Dark;
                    font-size: 90px;
                    text-align: center;
                    color: green;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .downArrow {
          margin-top: 10px;
          img {
            transform: rotate(180deg);
            margin-bottom: -28px;
          }
        }
      }
    }
  }
}
