$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.headerContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  .headerContent {
    display: flex;
    gap: 14%;
    padding: 45px 40px 1px 40px;
    .brainImage {
      img {
        height: 170px;
      }
    }
    .headerText {
      font-family: $font-family-Extra-Dark;
      color: #232323;
      font-size: 37px;
      line-height: 50px;
      text-align: center;

    }
  }
  .headerIcon {
    position: absolute;
    top: -24px;
    right: -34px;
    display: flex;
    padding-right: 10px;
    img {
      height: 100px;
      border-top-right-radius: 8px;
    }
  }
}
.mainContainer {
  display: flex;
  flex-direction: column;
  .containerWrapper1 {
    display: flex;
    padding-top: 10px;
    justify-content: space-around;
    .containerText {
      font-family: $font-family-Regular;
      font-size: 15px;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      max-width: 460px;
      .textWrapper1 {
        text-align: center;
      }
    }
    .imageContainer {
      margin-top: -10px;
      img {
        height: 160px;
      }
    }
  }

  .containerWrapper2 {
    display: flex;
    padding: 10px;
    .containerText {
      font-family: $font-family-Regular;
      font-size: 15px;
      line-height: 20px;
      justify-content: center;
      align-items: center;
      max-width: 490px;
      .textWrapper1 {
        text-align: center;
      }
    }
    .imageContainer {
      padding-top: 70px;
      padding-left: 10px;
      img {
        height: 245px;
      }
    }
  }
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .footerText {
    font-family: $font-family-Extra-Dark;
    font-size: 45px;
    margin-bottom: 20px;
  }
  .copyRight {
    font-family: $font-family-Light;
    font-size: 16px;
    margin-top: -30px;
  }
}

@media (min-width: 1025px) and (max-width: 1350px) {
  .mainContainer {
    .containerWrapper1 {
      .containerText {
        font-size: 12px;
      }
      .imageContainer {
        img {
          height: 140px;
        }
      }
    }
    .containerWrapper2 {
      .containerText {
        font-size: 12px;
      }
      .imageContainer {
        img {
          height: 200px;
        }
        padding-top: 50px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .headerContainer {
    .headerContent {
      padding: 30px 30px 1px 30px;
      .brainImage {
        img {
          height: 150px;
        }
      }
      .headerText {
        font-size: 34px;
      }
    }
    .headerIcon {
      img {
        height: 80px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      .containerText {
        max-width: 390px;
        font-size: 12px;
        line-height: 18px;
      }
      .imageContainer {
        img {
          height: 120px;
        }
      }
    }
    .containerWrapper2 {
      .containerText {
        font-size: 12px;
        line-height: 18px;
      }
      .imageContainer {
        img {
          height: 195px;
        }
      }
    }
  }
  .footerContainer {
    .footerText {
      font-size: 45px;
    }
    .copyRight {
      margin-top: -22px;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .headerContainer {
    .headerContent {
      padding: 15px 30px 1px 30px;
      gap: 12%;
      .brainImage {
        img {
          height: 120px;
        }
      }
      .headerText {
        font-size: 30px;
        line-height: 33px;
      }
    }
    .headerIcon {
      img {
        height: 70px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      .containerText {
        max-width: 360px;
        font-size: 10px;
        line-height: 17px;
      }
      .imageContainer {
        img {
          height: 100px;
        }
      }
    }
    .containerWrapper2 {
      .containerText {
        font-size: 10px;
        line-height: 18px;
        max-width: 400px;
      }
      .imageContainer {
        img {
          height: 180px;
        }
      }
    }
  }
  .footerContainer {
    .footerText {
      font-size: 32px;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .headerContainer {
    .headerContent {
      flex-wrap: wrap;
      justify-content: center;
      padding: 1px;
      .brainImage {
        img {
          height: 100px;
        }
      }
      .headerText {
        font-size: 20px;
        line-height: 33px;
      }
    }
    .headerIcon {
      position: absolute;
      img {
        height: 50px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      flex-wrap: wrap;
      .containerText {
        margin-top: 10px;
        font-size: 10px;
        line-height: 15px;
      }
      .imageContainer {
        img {
          height: 80px;
          min-width: 125px;
        }
      }
    }
    .containerWrapper2 {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: -20px;
      .containerText {
        font-size: 10px;
        line-height: 15px;
      }
      .imageContainer {
        padding-top: 25px;
        img {
          height: 80px;
          min-width: 125px;
        }
      }
    }
  }
  .footerContainer {
    .footerText {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 0;
    }
    .copyRight {
      margin-top: 10px;
      font-size: 13px;
    }
  }
}
