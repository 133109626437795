$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.headerContainer {
  display: flex;
  justify-content: center;
  position: relative;

  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .boyImage {
      img {
        height: 150px;
      }
    }
    .headerText {
      font-family: $font-family-Extra-Dark;
      color: #232323;
      font-size: 42px;
      line-height: 60px;
      text-align: center;
      max-width: 375px;
    }
  }
  .headerIcon {
    position: absolute;
    display: flex;
    top: -24px;
    right: -24px;

    img {
      height: 100px;
      border-radius: 1px 5px 1px 1px;
    }
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  .containerWrapper1 {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    .containerText {
      font-family: $font-family-Regular;
      font-size: 15px;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      padding: 1px 10px 1px 10px;
      .textWrapper1 {
        text-align: center;
        @media (max-width:576px){
          margin-top: 6px;
        }
      }
      .textWrapper2 {
        text-align: center;
        @media (max-width:576px){
          margin-top: 6px;
        }
        p {
          margin-top: -15px;
        }
        .linkWrapper {
          margin-top: -15px;
          font-family: $font-family-Regular;
          color: #232323;
          font-size: 15px;
          margin-bottom: -10px;
          @media (max-width: 480px) {
            font-size: 12px;
          }
          a {
            color: #232323;
          }
        }
      }
    }

    .imageContainer {
      padding-top: 20px;
      img {
        height: 140px;
      }
    }
  }
  .containerWrapper2 {
    display: flex;
    font-family: $font-family-Regular;
    font-size: 15px;
    justify-content: space-between;
    gap: 2%;
    align-items: center;
    line-height: 22px;
    .textContainer1 {
      text-align: center;
      p {
        margin-top: -15px;
      }
      .linkWrapper {
        margin-top: -15px;
        font-family: $font-family-Regular;
        color: #232323;
        font-size: 15px;
        margin-bottom: -10px;
        @media (max-width: 480px) {
          font-size: 12px;
        }
        a {
          color: #232323;
        }
      }
    }
    .textContainer2 {
      margin-right: 20px;
      .imageContainer {
        img {
          height: 125px;
        }
      }
      .imageText {
        padding: 10px 15px 1px 15px;
        text-align: center;
        font-size: 17px;
      }
    }
  }
  margin-bottom: 15px;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .copyRight {
    font-family: $font-family-Light;
    font-size: 16px;
    margin-top: -30px;
    @media (max-width: 480px){
      margin-top: -10px;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1350px) {
  .mainContainer {
    .containerWrapper1 {
      .containerText {
        font-size: 12px;
      }
    }
    .containerWrapper2 {
      font-size: 12px;
      .textContainer2 {
        .imageContainer {
          img {
            height: 120px;
          }
          .imageText {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .headerContainer {
    .headerContent {
      .headerText {
        font-size: 37px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      .containerText {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .containerWrapper2 {
      font-size: 13px;
      line-height: 20px;
      .textContainer2 {
        .imageContainer {
          img {
            height: 102px;
          }
        }
        .imageText {
          font-size: 14px;
          padding: 5px;
        }
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .headerContainer {
    .headerContent {
      .boyImage {
        img {
          height: 125px;
        }
      }
      .headerText {
        font-size: 32px;
        line-height: 47px;
        max-width: 365px;
      }
    }
    .headerIcon {
      img {
        height: 50px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      .imageContainer {
        img {
          height: 90px;
        }
      }
      .containerText {
        font-size: 12px;
        line-height: 18px;
        .textWrapper2 {
          .linkWrapper {
            font-size: 12px;
          }
        }
      }
    }
    .containerWrapper2 {
      font-size: 12px;
      line-height: 18px;
      .textContainer1 {
        max-width: 300px;
      }
      .textContainer2 {
        .imageContainer {
          img {
            height: 65px;
          }
        }
        .imageText {
          font-size: 15px;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .headerContainer {
    flex-direction: column-reverse;
    .headerContent {
      .boyImage {
        img {
          height: 100px;
        }
      }
      .headerText {
        font-size: 23px;
        line-height: 35px;
        max-width: 225px;
      }
    }
    .headerIcon {
      img {
        height: 50px;
      }
    }
  }
  .mainContainer {
    .containerWrapper1 {
      flex-wrap: wrap;
      margin-bottom: 10px;
      .containerText {
        font-size: 10px;
        .linkWrapper {
          font-size: 12px;
        }
      }
      .imageContainer {
        img {
          height: 80px;
        }
      }
    }
    .containerWrapper2 {
      font-size: 10px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .textContainer1 {
        .linkWrapper {
          font-size: 12px;
        }
      }
      .textContainer2 {
        margin: 10px 1px 1px 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .imageContainer {
          img {
            height: 90px;
          }
        }
        .imageText {
          font-size: 10px;
        }
      }
    }
  }
  .footerContainer {
    margin-top: -10px;
  }
}
