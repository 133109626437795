$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  @include contentHeight;

  @media (max-width: 768px) and (min-height: 800px){
    justify-content: center;
  }

  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 63%;
    padding: 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (max-width: 1259px) {
      padding: 20px;
    }
    @media (min-width: 700px) and (max-width: 1250px) {
      width: 70%;
    }
    @media (min-width: 500px) and (max-width: 700px) {
      width: 95%;
    }
    @media (min-width: 200px) and (max-width: 500px) {
      box-shadow: none;
      width: 100%;
    }
  }

  .quizWrapper {
    position: relative;

    .playBtn {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (min-width: 700px) and (max-width: 1250px) {
        width: 45px;
      }
      @media (min-width: 500px) and (max-width: 700px) {
        width: 30px;
      }
      @media (min-width: 200px) and (max-width: 500px) {
        width: 20px;
      }
    }
  }
}
