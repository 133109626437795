$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;

.main {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-width: 350px;
  margin-left: auto;
}

.searchMain {
  display: flex;
  align-items: center;
  width: 30%;
}
.search {
  border-radius: 8px;

  display: flex;
  border-width: 1px;
  border-color: black;
}
.searchText {
  font-size: medium;
  display: flex;
  font-weight: bold;
  margin-right: 10px;
}

.sub_main {
  margin-right: 5%;
  display: flex;
}
.dropdownStyle {
  width: 150px;
  margin-left: 10px;
  // margin-bottom: 20px;
}
.filterStyle {
  font-size: medium;
  display: flex;
  font-weight: bold;
}
.btnStyle {
  background: #000 !important;
  border-radius: 100px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #fff !important;
  margin-top: 15px !important;
  // width: 30%;
  // height: 40px !important;
  justify-content: center !important;
}

.questionStyle{
  font-weight: bold;
  font-size: 15px;
}

.feedback{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}