$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  min-height: 776px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  
  @media (min-height: 950px){
    height: calc(100vh - 213px);
  }

  @media (max-width: 865px){
    align-items: flex-start;
  }

  .modalContainer {
    @media (max-width: 1180px) {
      margin: 100px;
    }
    @media (max-width: 1024px) {
      margin: 40px;
    }
    margin: 35px;
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media screen and (max-width: 1510px) {
      width: 77%;
    }
    @media screen and (max-width: 1281px) {
      width: 85%;
    }
    @media screen and (max-width: 500px) {
      box-shadow: none;
    }
    .questionContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
      padding: 30px;
      @media screen and (max-width: 1180px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (max-width: 865px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 580px) {
        grid-template-columns: 1fr;
      }

      .activeContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid #ff1616;
        background: #ffffff;
        box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
        border-radius: 10px;
        padding: 10px 15px 1px 25px;
        cursor: pointer;
        gap: 2%;

        .numberContainer {
          font-family: $font-family-Dark;
          font-size: 22px;
          color: #232323;
          padding: 5px;

          .number1 {
            padding-left: 10px;
            color: #130f40;
          }

          .number2 {
            margin-top: -10px;
            color: #130f40;
          }

          .answer {
            hr {
              border: 1px solid #130f40;
              margin-top: -5px;
              margin-bottom: 5px;
            }

            :global(.Input_inputWrapper__faV4N .Input_inputClass__dfqtd) {
              margin-top: -1px;
              border: 1px solid #cfc5eaeb;
              border-radius: 1px;
              width: 30px;
              padding: 1px 5px 1px 1px;
              cursor: pointer;
              text-align: right;
              font-size: 18px;
              font-family: $font-family-Regular;
              color: #130f40;
            }
          }
        }

        .textContainer {
          max-width: 183px;
          text-align: left;
          font-family: $font-family-Regular;
          font-size: 20px;
          line-height: 30px;
          color: #232323;
          margin-top: -25px;
        }
      }

      .disabledView {
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
      }

      .detailContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid #ffffff;
        background: #ffffff;
        box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
        border-radius: 10px;
        padding: 10px 15px 1px 25px;
        cursor: pointer;
        gap: 2%;

        .numberContainer {
          font-family: $font-family-Dark;
          font-size: 22px;
          color: #232323;
          padding: 5px;

          .number1 {
            padding-left: 10px;
            color: #130f40;
          }

          .number2 {
            margin-top: -10px;
            color: #130f40;
          }

          .answer {
            hr {
              border: 1px solid #130f40;
              margin-top: -5px;
              margin-bottom: 5px;
            }

            :global(.Input_inputWrapper__faV4N .Input_inputClass__dfqtd) {
              margin-top: -1px;
              border: 1px solid #cfc5eaeb;
              border-radius: 1px;
              width: 30px;
              padding: 1px 5px 1px 1px;
              cursor: pointer;
              text-align: right;
              font-size: 18px;
              font-family: $font-family-Regular;
              color: #130f40;
            }
          }
        }

        .textContainer {
          max-width: 183px;
          text-align: left;
          font-family: $font-family-Regular;
          font-size: 20px;
          line-height: 30px;
          color: #232323;
          margin-top: -25px;
        }

        &:focus {
          border: 2px solid #ff1616;
        }
      }
    }
    .footerContainer {
      display: none;
    }
    .activeFooterContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 1px 20px 1px;
      width: 100%;
      :global(.ant-btn-primary) {
        margin-top: 8px;
        background: $btn-color;
        border-radius: 10px;
        font-family: $font-family-Extra-Light;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        border-color: $btn-color;
        width: 50%;
        margin-bottom: 20px;
        height: 40px;
        @media screen and (max-width: 650px) {
          width: 90%;
        }

        &:hover {
          background: $btn-color;
          color: #ffffff;
        }
      }
    }
  }
}
