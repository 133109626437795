$font-family-Regular: 'Poppins-Regular';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$title-color: #130f40;
$text-color: #000000;
.titleContainer {
  height: 12%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $font-family-Regular;
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: 40px;
  line-height: 60px;
  color: $title-color;
  margin-top: 20px;
  position: relative;
  width: 85%;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 650px) {
    font-size: 18px;
  }

  .backContainer {
    padding-right: 20px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: -3px;
    left: 0;
    cursor: pointer;
    svg{
      margin-top: 0 !important;
    }
    @media (max-width: 650px) {
      top: -13px; 
      margin-top: 15px !important;
      svg{
        height: 17px;
      }
    }
    @media (max-width: 500px){
      display: none;
    }
  }
}
