$font-family-Light: 'Poppins-Light';
$font-family-Regular: 'Poppins-Regular';
$font-style: normal;
$btn-color: #f6ef25;
.mainCls {
  height: auto;
  margin-bottom: 30px;
}

.hideForm {
  height: 502px;
  @media (max-width: 600px) {
    height: 780px;
  }
}

.mainContainer {
  display: flex;
  gap: 2%;
  justify-content: center;

  .displayFormContainer {
    display: block;
    @media (max-width: 600px) {
      // display: none;
      width: 90%;
      margin: auto;
    }
  }

  .DisplayContainer {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }

  .parentDropDown {
    width: 23%;
    margin-top: -65px;
    height: 30px;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 1200px) {
      width: 318px;
      max-width: 100%;
    }
    @media (max-width: 768px) {
      width: 70%;
    }
    @media (max-width: 600px) {
      margin-top: -100px;
      box-shadow: none;
      width: 100%;
      background: none;
    }

    .headerTextCls {
      border: 2px solid white;
      @media (max-width: 600px) {
        border: 2px solid #ff1616;
      }
    }

    .parentHeader {
      display: flex;
      height: 35px;
      justify-content: center;
      align-items: center;
      font-family: $font-family-Regular;
      font-style: $font-style;
      font-weight: 600;
      font-size: 16px;
      background: #ffffff;
      box-shadow: 0px 4px 14px rgba(19, 15, 64, 0.4);
      border-radius: 10px;
      @media (max-width: 600px) {
        margin: auto;
        width: 90%;
        line-height: 24px;
      }

      .headerText {
        margin-top: 2px;
      }

      .UpperArrow {
        margin-left: 10px;
        margin-top: 7px;
        @media (max-width: 600px) {
          display: none;
        }
      }
    }

    .formContainer {
      @media (max-width: 600px) {
        margin-top: 20px;
        background: #ffffff;
        box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
        border-radius: 10px;
        width: 100%;
      }

      .formInput {
        margin-top: 10px;
        padding: 20px 16px;

        :global(.ant-form-item-explain-error) {
          text-align: left;
        }
        :global(.ant-row) {
          flex-direction: column;
        }
        :global(.ant-form-item) {
          margin: 10px 1px 5px 1px;
        }
        :global(.ant-form-item-label) {
          :global(label) {
            font-size: 16px;
            white-space: initial !important;
            display: inline-block;
          }
        }

        :global(.ant-btn-primary) {
          margin-top: 20px;
          width: 100%;
          background: $btn-color;
          border-radius: 100px;
          font-family: $font-family-Light;
          font-style: $font-style;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: #232323;
          border-color: $btn-color;
          margin-bottom: 20px;
          @media (max-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
          }

          &:hover {
            color: #1312dd;
          }
        }

        hr {
          margin-top: 10px;
          margin-bottom: -5px;
          color: #130f40;
        }

        :global(.ant-btn-link) {
          display: flex;
          font-family: $font-family-Regular;
          font-style: $font-style;
          font-weight: 400;
          font-size: 16px;
          color: #1312dd;
        }

        :global(.ant-btn) {
          padding: 0;
          margin-top: 10px;
        }
      }
    }
  }
}


.formInput {
  :global( .ant-switch-inner) {
    font-size: 14px !important;
  }

  :global(.ant-switch-inner:not(.ant-switch-checked)) {
    margin-left: 25px !important;
  }
  
  

  :global(.ant-switch:not(.show-input) .ant-switch-handle) {
    top: 7px !important;
    left: 7px !important;
  }

  :global(.ant-switch-checked .ant-switch-handle) {
    top: 7px !important;
    left: calc(100% - 18px - 7px) !important;
  }

  :global(.ant-switch) {
    margin-bottom: 6px;
    background-color: #1890ff !important;
    width: 100px !important;
    height: 33px !important;
  }

  @media (max-width: 319px) {
    :global(.ant-switch-inner) {
      font-size: 12px !important;
    }

    :global(.ant-switch) {
      height: 28px !important;
    }
    :global( .ant-switch-inner) {
      font-size: 12px !important;
    }

    :global(.ant-switch:not(.show-input) .ant-switch-handle) {
      top: 7px !important;
      left: 5px !important;
    }

    :global(.ant-switch-handle) {
      width: 12px !important;
      height: 12px !important;
  }
  
  :global(.ant-switch-inner:not(.ant-switch-checked)) {
    margin-left: 18px !important;
  }
  }

}