$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.downloadIcon {
}
.mainContainer {
  min-height: 752px;
  .headingWrapper {
    display: flex;
    align-items: center;
    max-width: 80%;
    margin: auto;
    padding: 10px 0;
  }
  .backIcon {
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
      @media (max-width: 576px) {
        height: 15px;
        width: 15px;
      }
    }
  }
  .heading {
    padding: 10px 10px 10px 20px;
    text-align: left;
    font-family: $font-family-Regular;
    font-size: 30px;
    color: #8e8a8a;
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
  :global(.ant-table-content) {
    font-family: $font-family-Regular;
    font-size: 15px;
  }
  :global(.ant-table-thead .ant-table-cell) {
    font-family: $font-family-Dark;
    font-size: 15px;
  }
  :global(.ant-table-tbody) {
    :global(tr) {
      :global(td) {
        border-bottom: 2px solid #f0f0f0;
      }
    }
  }
  .tableWrapper {
    max-width: 80%;
    margin: auto;
  }
}
