$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;

.search {
  border-radius: 10px;
  // max-width: max-content;
  display: flex;
  margin-bottom: auto;
  height: 45px;
}
.table {
  // margin-top: 30px;
}
