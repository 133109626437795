$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;
.mainContainer {
  min-height: 776px;
  font-size: 30px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 650px){
    padding: 25px;
  }
  @media (max-width: 450px){
    padding: 25px 15px;
  }

  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    padding: 10px;
    @media (max-width: 1024px){
      max-width: 656px;
      width: 100%;
    }
    @media (max-width: 500px) {
      box-shadow: none;
    }
    .instruction {
      font-family: $font-family;
      font-size: 20px;
      @media (max-width: 1024px){
        font-size: 16px;
      }
      @media (max-width: 650px){
        max-width: 300px;
        text-align: center;
      }
    }

    .quizWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10%;
      height: 500px;
      margin-top: 75px;
      @media (max-width: 1400px) {
        flex-direction: column;
        margin-top: 300px;
      }
      @media (max-width: 1100px) {
        height: 800px;
        margin-top: 1px;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        height: 600px;
      }

      .quizImageBody {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 30px 30px 30px;

        background-repeat: no-repeat;
        @media (max-width: 1100px) {
          padding: 200px 30px 30px 30px;
        }
        @media (max-width: 768px) { 
          padding: 100px 30px 30px 30px;
        }
        @media (max-width: 500px) { 
          width: 100%;
        }

        .triangle {
          position: relative;
          transform: scale(var(--ggs, 1.3));
          width: 416px;
          height: 192px;
          border-left: 3px solid transparent;
          border-bottom: 15px solid #5858fe;
          border-radius: 10px;
          @media (max-width: 1100px) {
            transform: scale(var(--ggs, 1));
          }
          @media (max-width: 800px) {
            transform: scale(var(--ggs, 0.7));
          }
        }

        .triangle,
        .triangle::before {
          border-right: 4px solid transparent;
        }

        .triangle::before {
          content: '';
          position: absolute;
          width: 363px;
          height: 365px;
          border-left: 15px solid #5858fe;
          border-top: 15px solid #5858fe;
          border-bottom: 10px solid transparent;
          transform: rotate(45deg) skew(10deg, 9deg);
          left: 25px;
          border-radius: 20px;
          @media (max-width: 450px){
            left: 20px;
          }
        }

        .subtriangle {
          position: relative;
          transform: scale(var(--ggs, 0.9));
          width: 385px;
          height: 240px;
          border-left: 3px solid transparent;
          border-bottom: 2px dotted #5858fe;
          border-radius: 15px;
          margin-left: 16px;
          @media (max-width: 450px){
            margin-left: 12px;
            width: 389px;
          }

          .imageWrapper {
            img {
              height: 120px;
              margin-top: 10px;
            }
          }

          .numberUpper {
            margin-top: -60px;
            margin-left: -5px;
            font-size: 65px;
            line-height: 75px;
            font-family: $font-family-Regular;
            color: #5858fe;
          }

          .numberWrapper {
            display: flex;
            justify-content: space-around;
            margin-top: -30px;
            font-size: 65px;
            line-height: 75px;
            font-family: $font-family-Regular;

            .numberLeft {
              margin-left: -63px;
              margin-top: -5px;
              color: #5858fe;
            }

            .numberRight {
              margin-top: -5px;
              margin-right: -63px;
              color: #ff0500;
            }
          }
        }

        .subtriangle,
        .subtriangle::before {
          border-right: 2px solid transparent;
        }

        .subtriangle::before {
          content: '';
          position: absolute;
          width: 330px;
          height: 330px;
          border-left: 2px dotted #5858fe;
          border-top: 2px dotted #5858fe;
          border-bottom: 10px solid transparent;
          transform: rotate(45deg) skew(10deg, 9deg);
          left: 23px;
          border-radius: 15px;
          margin-top: 75px;
          @media (max-width: 450px){
            left: 22px;
          }
        }
      }
      .infinityNumberWrapper {
        display: flex;
        justify-content: space-around;
        font-size: 80px;
        line-height: 75px;
        font-family: $font-family-Regular;

        .numberLeft {
          margin-left: -63px;
          margin-top: -5px;
          color: #5858fe;
        }

        .numberRight {
          margin-top: -5px;
          margin-right: -63px;
          color: #ff0500;
        }
        @media (max-width: 890px) {
          font-size: 50px;
        }
        @media (max-width: 730px) {
          font-size: 50px;
          gap: 20%;
        }
      }

      .questionOperations {
        font-size: 50px;
        line-height: 75px;
        font-family: $font-family-Regular;
        margin-top: 20px;
        @media (max-width: 1400px) {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          gap: 50%;
        }
        @media (max-width: 1100px) {
          gap: 30%;
        }
        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 980px) {
          gap: 20%;
        }
        @media (max-width: 890px) {
          font-size: 35px;
        }
        @media (max-width: 730px) {
          font-size: 35px;
          gap: 20%;
        }

        .questionBody {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 150px;
          @media (max-width: 1024px){
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 0px;
          }
          @media (max-width: 450px){
            justify-content: space-between;
          }

          .questionRow{
            @media (max-width: 1024px){
              flex-direction: column;
              display: flex;
            }
          }

          .questionWrapper {
            display: flex;
            gap: 2%;
            align-items: center;
            height: 85px;
            .numberWrapper {
              color: #055ced;
            }
            .infinityNumberWrapper {
              color: #055ced;
            }

            .signWrapper {
              color: #ff0500;
            }

            .answerWrapper {
              :global(.Input_inputWrapper__faV4N .Input_inputClass__dfqtd) {
                border: none;
                background: #ffffff;
                border-bottom: 3px solid #ff1616;
                border-radius: 1px;
                width: 73px;
                padding: 2px;

                font-family: $font-family-Regular;
                font-size: 50px;
                color: #ff0500;
                text-align: center;
                cursor: pointer;
                margin-top: 31px;
                line-height: 52px;
                @media (max-width: 890px) {
                  font-size: 35px;
                  line-height: 36px;
                }
                @media (max-width: 730px) {
                  font-size: 35px;
                  gap: 20%;
                }
                @media (max-width: 450px){
                  width: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
