$font-family: 'Poppins', sans-serif;
$btn-color: #ff1616;

.questionStyle {
  font-weight: bold;
  font-size: 15px;
}

.showMoreLink {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}