$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.headerContainer {
  font-family: $font-family-Extra-Dark;
  color: #ff1616;
  font-size: 45px;
  text-align: center;
  padding-top: 5px;
}
.mainContainer {
  .container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px;
    .imageWrapper {
      img {
        padding-left: 22px;
        height: 200px;
      }
    }
    .textWrapper {
      font-family: $font-family-Dark;
      color: #4f4fdc;
      font-size: 17px;
      line-height: 22px;
      max-width: 340px;
      margin-top: -40px;
      .linkWrapper {
        margin-top: -15px;
        font-size: 13px;
        line-height: 20px;
        a {
          color: #232323;
        }
      }
    }
    .textWrapper1 {
      font-family: $font-family-Dark;
      color: #4f4fdc;
      font-size: 17px;
      line-height: 22px;
      max-width: 340px;
      .linkWrapper {
        margin-top: -15px;
        font-size: 13px;
        line-height: 20px;
        @media (max-width: 450px) {
          font-size: 12px;
        }
        a {
          color: #232323;
        }
      }
    }
  }

  .container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 2%;
    .imageWrapper {
      padding-right: 40px;
      img {
        height: 200px;
      }
    }
    .textWrapper {
      font-family: $font-family-Dark;
      color: #4f4fdc;
      font-size: 17px;
      line-height: 22px;
      max-width: 300px;
      span {
        color: #232323;
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 1px;
        padding-left: 5px;
      }
    }
  }
}

.footerContainer {
  text-align: center;
  img {
    height: 50px;
  }
}

@media (min-width: 1025px) and (max-width: 1350px) {
  .mainContainer {
    .container1 {
      .imageWrapper {
        img {
          height: 160px;
        }
      }
      .textWrapper {
        font-size: 15px;
      }
    }
    .container2 {
      .imageWrapper {
        img {
          height: 160px;
        }
      }
      .textWrapper {
        font-size: 15px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .mainContainer {
    .container1 {
      .imageWrapper {
        img {
          height: 175px;
        }
      }
      .textWrapper {
        font-size: 15px;
        line-height: 20px;
      }
      .textWrapper1 {
        font-size: 15px;
        line-height: 20px;
      }
    }
    .container2 {
      .imageWrapper {
        img {
          height: 175px;
        }
      }
      .textWrapper {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .headerContainer {
    font-size: 40px;
  }
  .mainContainer {
    .container1 {
      .imageWrapper {
        img {
          height: 150px;
          padding: 5px;
        }
      }
      .textWrapper {
        margin-top: -10px;
        font-size: 13px;
        line-height: 20px;
      }
      .textWrapper1 {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .container2 {
      .imageWrapper {
        img {
          height: 150px;
          padding: 5px;
        }
      }
      .textWrapper {
        font-size: 13px;
        line-height: 18px;
        span {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .headerContainer {
    font-size: 33px;
  }
  .mainContainer {
    .container1 {
      flex-direction: column;
      flex-wrap: wrap;
      .imageWrapper {
        img {
          height: 95px;
        }
      }
      .textWrapper {
        margin-top: 10px;
        font-size: 11px;
        line-height: 13px;
      }
      .linkWrapper {
        font-size: 12px;
      }
    }
    .container2 {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      .imageWrapper {
        padding: 1px;
        margin-left: 30px;
        img {
          height: 95px;
        }
      }
      .textWrapper {
        font-size: 11px;
        line-height: 13px;
        span {
          font-size: 12px;
          line-height: 10px;
        }
      }
    }
  }
}
