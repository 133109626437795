$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$font-style: normal;
$font-weight: 400;
$link-color: #1312dd;
$btn-color: #f6ef25;
:global(.ant-modal-root) {
  :global(.ant-modal-mask) {
    background-color: rgba(35, 35, 35, 0.9);
    backdrop-filter: blur(3px);
  }
}
:global(.ant-modal-header) {
  border-bottom: none;
}
.imgHeader {
  margin-left: -26px;
  margin-top: -25px;
  border-radius: 18px 13px 1px 1px;
  @media (max-width: 576px){
    margin-left: -2px;
    width: 100.5%;
    margin-top: -28px;
  }
}

.checkoutModalStyle {
  @media(max-width: 576px){
    width: 90% !important;
  }
  :global(.ant-modal-content) {
    border-radius: 10px;
  }

  :global(.ant-modal-close-x) {
    margin-top: -60px;
    margin-left: -40px;
    position: absolute;
    color: #ffffff;
  }

  :global(.ant-modal-header) {
    padding: 16px 24px 0 24px;
    @media (max-width: 576px){
      padding: 16px 0 0;
    }
  }

  .cogplayIcon {
    position: absolute;
    margin-top: -155px;
    margin-left: 10px;
    @media (max-width: 400px){
      margin-top: -133px;
    }
  }

  .header {
    width: 471.8px;
  }

  .mainContainer {
    padding: 10px 20px 20px 20px;
    .paymentText {
      font-family: $font-family-Regular;
      font-size: 20px;
      text-align: center;

      .subHeadingText {
        margin-top: 10px;
        font-family: $font-family-Light;
        font-size: 18px;
      }
      .subHeadingConfirmText {
        margin-top: 10px;
        font-family: $font-family-Light;
        font-size: 15px;
      }
      .subConfirmText {
        font-size: 17px;
      }
    }

    .subHeadingText {
      margin-top: 10px;
      font-family: $font-family-Regular;
      font-size: 20px;
    }

    .successText {
      margin-top: 10px;
      font-family: $font-family-Regular;
      font-size: 23px;
    }

    .number {
      font-family: $font-family-Regular;

      .numberInput {
        border: 1px solid #130f40;
        border-radius: 7px;
        padding: 7px 8px;
        margin-bottom: 8px;

        &:hover {
          border-color: #40a9ff;
          border-right-width: 1px;
          z-index: 1;
        }
      }
    }

    .container {
      display: flex;
      gap: 1%;
      font-family: $font-family-Regular;

      .expiryDateWrapper {
        width: 50%;

        .DateInput {
          border: 1px solid #130f40;
          border-radius: 7px;
          padding: 7px 8px;
          margin-bottom: 10px;

          &:hover {
            border-color: #40a9ff;
            border-right-width: 1px;
            z-index: 1;
          }
        }
      }

      .codeWrapper {
        width: 50%;

        .codeInput {
          border: 1px solid #130f40;
          border-radius: 7px;
          padding: 7px 8px;
          margin-bottom: 10px;

          &:hover {
            border-color: #40a9ff;
            border-right-width: 1px;
            z-index: 1;
          }
        }

        .secureButton {
          position: absolute;
          width: 1%;
          margin-top: -23px;
          margin-left: 85px;
          color: #232323;
        }
      }
    }
  }

  .paymentFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .orderText {
      font-family: $font-family-Regular;
      font-size: 20px;
    }

    .detailContainer {
      width: 95%;

      .plan {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;

        .planHeading {
          font-size: 15px;
          font-family: $font-family-Light;
        }

        .planDetail {
          font-size: 15px;
          font-family: $font-family-Regular;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;

        .totalHeading {
          font-size: 20px;
          font-family: $font-family-Light;
        }

        .totalDetail {
          font-size: 20px;
          font-weight: bold;
          font-family: $font-family-Dark;
        }
      }
    }

    :global(.ant-btn-primary) {
      margin-top: 20px;
      width: 100%;
      background: $btn-color;
      border-radius: 100px;
      font-family: $font-family-Extra-Light;
      font-style: $font-style;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #232323;
      border-color: $btn-color;

      &:hover {
        color: #1312dd;
      }
    }
  }

  :global(.ant-btn-link) {
    color: #1312dd;
    width: 100%;
    font-family: $font-family-Medium;
    display: flex;
    justify-content: center;

    &:hover {
      color: #1890ff;
    }
  }
}

:global(.ant-popover-inner) {
  width: 65%;
}

:global(.ant-popover-inner-content) {
  background: #ffffff;
  box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
  border-radius: 10px;

  .secureModal {
    .textFirst {
      margin-top: 13px;
      display: flex;
      justify-content: space-between;

      .visaText {
        width: 72%;
        font-family: $font-family-Regular;
        font-size: 14px;

        span {
          font-family: $font-family-Dark;
        }
      }

      .cardImg {
      }
    }
  }
}
