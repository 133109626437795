$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$font-family-Light: 'Poppins-Light';
.mainFooter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #02001c;
  //margin-top: 93px;
  // position: fixed;
  // width: 100%;
  // bottom: 0;
  // left: 0;
  &.fixedFooter{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .footerTab {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    .cogPlayIcon {
      width: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 20%;
      color: #ffffff;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
      margin-left: 10%;
      margin-top: -10px;
    }
    .footerText {
      margin-left: 100px;
      width: 55%;
      font-family: $font-family-Regular;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 35px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      .TabText {
        margin-left: 7%;
        cursor: pointer;
        color: #ffffff;
        &:hover {
          transition: 0.5s;
          font-family: $font-family-Regular;
          color: #f6ef25;
        }
      }
      @media screen and (max-width: 992px) {
        margin-left: 47px;
      }
    }
    .socialMedia {
      cursor: pointer;
      display: flex;
      justify-content: center;
      gap: 35%;
    }
  }

  .copyRight {
    width: 100%;
    color: #d8d8d8;
    font-family: 'Poppins-Extra-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
  }
}

@media (max-width: 470px) and (min-width: 100px) {
  .mainFooter {
    border: 2px solid yellow;
    .footerTab {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .cogPlayIcon {
        margin-left: 1%;
      }
      .footerText {
        gap: 20%;
        margin-left: -10px;
        margin-top: 10px;
        font-family: $font-family-Regular;
        font-size: 16px;
      }
      .socialMedia {
        margin-top: 15px;
      }
    }
    .copyRight {
      margin-top: 30px;
    }
  }
}
