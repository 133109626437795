$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 35px;

  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 63%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (min-width: 700px) and (max-width: 1250px) {
      width: 70%;
    }
    @media (min-width: 500px) and (max-width: 700px) {
      width: 80%;
    }
    @media (min-width: 200px) and (max-width: 510px) {
      box-shadow: none;
    }

    .instruction {
      width: 65%;
      text-align: center;
      font-family: $font-family;
      font-size: 22px;
      @media (min-width: 700px) and (max-width: 1250px) {
        font-size: 20px;
        width: 70%;
      }
    }

    .quizWrapper {
      display: flex;
      column-gap: 40px;

      @media (min-width: 500px) and (max-width: 1250px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
      }
      @media (min-width: 200px) and (max-width: 510px) {
        flex-direction: column-reverse;
      }

      .quizContent {
        display: flex;

        .numberContent {
          color: #055ced;
          height: 50px;
          width: 70px;
          font-family: $font-family;
          font-weight: 400;
          font-size: 25px;
          line-height: 38px;
          border: 0.5px solid #232323;
        }
      }
    }
  }
}
