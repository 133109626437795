$font-family: 'Poppins', sans-serif;
$font-family-Light: 'Poppins-Light';
$font-family-Regular: 'Poppins-Regular';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$title-color: #130f40;
$text-color: #000000;
@use '../../../../assets/scss/common' as *;
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 27px 0;
  @include contentHeight;

  @media (max-height: 750px){
    justify-content: flex-start;
  }

  @media (max-width: 650px) {
    padding: 22px 0;
  }


  .passwordContainer {
    width: 70%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    @media (max-width: 650px) {
      width: 90%;
      max-height: unset;
    }

    .contentContainer {
      background: #ffffff;
      max-width: 80%;
      max-height: 80%;
      text-align: left;
      padding: 10px 30px;
      border: 1px solid #acacac;
      border-radius: 5px;

      .titleClass {
        color: $text-color;
        font-style: $font-style;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .formInput {
      width: 100%;
      padding: 0 30px;
      display: flex;
      flex-direction: row;

      .btnWrapper {
        text-align: center;
      }

      :global(.ant-row) {
        flex-direction: column;
      }

      :global(.ant-form) {
        width: 100%;
      }

      :global(.ant-form-item) {
        margin: 10px auto 5px;
        width: 40%;
        @media (max-width: 992px){
          width: 90%;
        }
      }

      :global(.ant-form-item-label) {
        label {
          font-size: 16px;
        }
      }

      :global(.ant-input-affix-wrapper) {
        border: 1px solid #130f40;
        border-radius: 10px;
      }

      :global(.ant-form-horizontal) {
        text-align: left;
      }

      :global(.ant-btn-primary) {
        margin-top: 30px;
        max-width: 306px;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #232323;
        border-color: $btn-color;
        margin-bottom: 30px;
        @media (max-width: 992px){
          width: 100%;
        }
      }

      :global(.ant-input-suffix) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(168, 155, 155, 0.88);
      }
    }
  }
}
