$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$font-style: normal;
$font-weight: 400;
$link-color: #1312dd;
$btn-color: #f6ef25;
:global(.ant-modal-root) {
  :global(.ant-modal-mask) {
    background-color: rgba(35, 35, 35, 0.9);
    backdrop-filter: blur(3px);
  }
}
:global(.ant-modal-header) {
  border-bottom: none;
}
.imgHeader {
  margin-left: -26px;
  margin-top: -25px;
  border-radius: 18px 13px 1px 1px;
  @media (max-width: 576px){
    margin-left: -2px;
    width: 100.5%;
    margin-top: -28px;
  }
}

.checkoutModalStyle {
  @media(max-width: 576px){
    width: 90% !important;
  }
  :global(.ant-modal-content) {
    border-radius: 10px;
  }

  :global(.ant-modal-close-x) {
    margin-top: -60px;
    margin-left: -40px;
    position: absolute;
    color: #ffffff;
  }

  :global(.ant-modal-header) {
    padding: 16px 24px 0 24px;
    @media(max-width: 576px){
      padding: 16px 0;
    }
  }

  .cogplayIcon {
    position: absolute;
    margin-top: -155px;
    margin-left: 10px;
  }

  .header {
    width: 471.8px;
  }

  .mainContainer {
    padding: 10px 20px 20px 20px;
    @media (max-width: 576px){
      padding: 10px 0;
    }
    .paymentText {
      font-family: $font-family-Regular;
      font-size: 20px;
      text-align: center;

      .subHeadingText {
        margin-top: 10px;
        font-family: $font-family-Light;
        font-size: 18px;
      }
      .subHeadingConfirmText {
        margin-top: 10px;
        font-family: $font-family-Light;
        font-size: 15px;
      }
      .subConfirmText {
        font-size: 17px;
      }
    }

    .subHeadingText {
      margin-top: 10px;
      font-family: $font-family-Regular;
      font-size: 20px;
    }

    .successText {
      margin-top: 10px;
      font-family: $font-family-Regular;
      font-size: 23px;
    }

    .formInput {
      // width: 100%;
      margin-top: 10px;
      // padding: 0 30px;
      display: flex;
      flex-direction: row;

      :global(.ant-form) {
        width: 100%;
      }
      :global(.ant-row) {
        flex-direction: column;
      }
      :global(.ant-form-item) {
        margin: 10px 1px 5px 1px;
      }

      :global(.ant-form-item-label) {
        :global(label) {
          font-size: 16px;
          white-space: initial !important;
          display: inline-block;
          @media (max-width: 576px){
            font-size: 14px;
          }
        }
      }

      :global(.ant-input-affix-wrapper) {
        border: 1px solid #130f40;
        border-radius: 10px;
      }

      :global(.ant-btn-primary) {
        margin-top: 20px;
        width: 100%;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #232323;
        border-color: $btn-color;
        @media screen and (max-width: 992px) {
          margin-bottom: 20px;
        }
        &:hover {
          color: #1312dd;
          border-color: #1312dd;
        }
      }

      :global(.ant-input-suffix) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(168, 155, 155, 0.88);
      }
      :global(.ant-form-item-explain-error) {
        text-align: left;
      }
    }
  }

  :global(.ant-btn-link) {
    color: #1312dd;
    width: 100%;
    font-family: $font-family-Medium;
    display: flex;
    justify-content: center;

    &:hover {
      color: #1890ff;
    }
  }
}

:global(.ant-popover-inner) {
  width: 65%;
}
