$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 35px;

  @media (min-height: 1050px){
    height: calc(100vh - 213px);
  }

  @media (max-width: 576px){
    padding: 25px;
  }

  @media (max-width: 1024px){
    max-width: 656px;
    margin: 0 auto;
  }

  .modalContainer {
    display: flex;
    row-gap: 28px;
    width: 63%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (min-width: 700px) and (max-width: 1024px) {
      width: 100%;
    }
    @media (min-width: 500px) and (max-width: 700px) {
      width: 90%;
    }
    @media (min-width: 200px) and (max-width: 510px) {
      box-shadow: none;
      width: 100%;
    }

    .instruction {
      width: 65%;
      text-align: center;
      font-family: $font-family;
      font-size: 22px;
      @media (max-width: 1024px) {
        font-size: 16px;
        max-width: 402px;
      }
    }

    .quizWrapper {
      padding-bottom: 10px;
      .quizContent {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-row-gap: 20px;
        border-radius: 5px;
        @media (max-width: 1024px) {
          grid-template-columns: repeat(5, 1fr);
          grid-row-gap: 10px;
        }
        .numberContent {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-family;
          font-weight: 400;
          width: 70px;
          height: 70px;
          font-size: 50px;
          border: 1px solid #232323;
          @media (max-width: 1250px) {
            width: 50px;
            height: 50px;
            font-size: 40px;
          }
          @media (max-width: 1024px) {
            width: 70px;
            height: 70px;
            font-size: 50px;
          }
          @media (max-width: 700px) {
            width: 70px;
            height: 70px;
          }
          @media (max-width: 360px) {
            width: 60px;
            height: 60px;
            font-size: 40px;
          }
        }
      }
    }

    .multiplyQuizWrapper {
      padding-bottom: 120px;
      .quizContent {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-row-gap: 2%;
        border-radius: 5px;
        @media (min-width: 200px) and (max-width: 700px) {
          grid-template-columns: repeat(9, 1fr);
        }
        .numberContent {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-family;
          font-weight: 400;
          width: 65px;
          height: 60px;
          font-size: 30px;
          border: 1px solid #232323;
          @media (min-width: 700px) and (max-width: 1250px) {
            width: 40px;
            height: 35px;
          }
          @media (min-width: 200px) and (max-width: 700px) {
            width: 40px;
            height: 35px;
          }
        }
      }
    }

    .audioWrapper {
      height: 156px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      span {
        font-family: $font-family-Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 10px;
      }
      :global(.ant-btn) {
        white-space: normal;
        word-wrap: break-word;
        font-family: $font-family-Light;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 38px;
        text-align: center;
        border: none;
        margin-bottom: 20px;
      }
    }
  }
  .recordingSpan{
    margin-bottom: -25px;
    margin-top: 55px;
    padding: 0;
  }
}


.closeButton {
  cursor: pointer;
  :global(.ant-btn) {
    pointer-events: none;
    background: #5858fe;
    border-radius: 10px;
    font-family: $font-family-Dark;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    height: 40px;
    @media (max-width: 650px) {
      width: 90%;
    }

    &:hover,
    &:focus,
    &:active {
      background: #5858fe;
      color: #ffffff;
    }
  }
}