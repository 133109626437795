$text-color: #232323;
$font-family-Regular: 'Poppins-Regular';
$font-family-Dark: 'Poppins-Dark';
$font-family-Light: 'Poppins-Light';
.NavLinkClass {
  font-family: $font-family-Regular;
  font-style: normal;
  color: $text-color;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}
.signupClass {
  margin-left: -50px;
  color: #ff1616;
  font-family: $font-family-Dark;
  @media (max-width: 1200px){
    margin-left: -36px;
  }
  @media (max-width: 992px){
    margin-left: 0;
  }
}
.headerSignup {
  margin-left: -50px;
  color: #ff1616;
  font-family: $font-family-Dark;
  @media (max-width: 1200px){
    margin-left: -36px;
  }
  @media (max-width: 992px){
    margin-left: -28px;
  }
  @media (max-width: 500px){
    margin-left: 0;
  }
}
.activeTab {
  height: 5px;
  padding: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-Dark;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #232323;
  cursor: pointer;
  &:hover {
    color: #232323;
  }
  @media (max-width: 1200px){
    font-size: 16px;
    font-weight: 700;
    padding: 28px 20px;
  }
  @media (max-width: 1024px){
    font-size: 14px;
  }
  @media (max-width: 768px){
    padding: 28px 14px;
  }
  @media (max-width: 500px){
    padding: 40px 0;
  }
}
.homeText {
  height: 5px;
  padding: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-Light;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  color: #232323;
  &:hover {
    color: #ff1616;
  }
  @media (max-width: 1200px){
    font-size: 16px;
    padding: 28px 20px;
  }
  @media (max-width: 1024px){
    font-size: 14px;
  }
  @media (max-width: 768px){
    padding: 28px 14px;
  }
}
