$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;

  .infoContainer {
    margin-top: -141px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2%;
    @media (max-width: 1280px) {
      margin-top: -35px;
    }
    @media (max-width: 1054px) {
      margin-top: -70px;
    }
    @media(max-width: 1024px){
      width: 656px;
      max-width: 100%;
      margin: -70px auto auto;
      gap: 20px;
      flex-wrap: nowrap;
    }
    @media (max-width: 800px) {
      flex-wrap: wrap;
      padding: 0 10px;
    }
    @media (max-width: 480px) {
      margin-top: -100px;
    }
    @keyframes modelPopup {
      from {
        transform: scale(1.1);
        transition: 1s;
      }
      to {
        transform: scale(1);
        transition: 1s;
      }
    }
    .modelHighlight {
      animation-name: modelPopup;
      animation-duration: 1.5s;
    }
    .detailContainer {
      gap: 2%;
      background: #ffffff;
      box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
      border-radius: 10px;
      width: 290px;
      @media (max-width: 1280px) {
        width: 230px;
      }
      @media (max-width: 1054px) {
        margin-top: 10px;
        width: 200px;
      }
      @media (max-width: 1024px) {
        margin-top: 10px;
        width: 149px;
      }

      .detailMainContainer {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        padding: 1px 20px 1px 20px;

        @media (max-width: 1024px){
          padding: 1px 9px;
          min-height: 107px;
        }

        .headingContainer {
          font-family: $font-family-Dark;
          font-size: 23px;
          line-height: 33px;
          text-align: center;
          color: #130f40;
          height: 70px;
          margin-top: 10px;
          padding: 1px 10px 1px 10px;
          @media (max-width: 1280px) {
            font-size: 19px;
            line-height: 20px;
            height: 40px;
          }
          @media (max-width: 1054px) {
            font-size: 18px;
            padding: 1px;
          }
          @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 21px;
          }
        }

        .subTextContainer {
          margin-top: 10px;
          font-family: $font-family-Light;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #232323;
          height: 125px;
          padding: 1px 15px 1px 15px;
          @media (max-width: 1280px) {
            font-size: 13px;
            line-height: 19px;
            font-family: $font-family-Regular;
            height: 100px;
            padding: 5px;
          }
          @media (max-width: 1054px) {
            font-size: 11px;
            font-family: $font-family-Regular;
            line-height: 20px;
            height: 90px;
            padding: 1px;
          }
          @media (max-width: 1024px) {
            font-size: 9px;
            line-height: 13.5px;
            height: 41px;
          }
        }
      }

      .buttonContainer {
        :global(.ant-btn-primary) {
          margin-top: 10px;
          width: 80%;
          background: #f6ef25;
          border-radius: 100px;
          font-family: $font-family-Light;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: #232323;
          border-color: #f6ef25;
          margin-bottom: 20px;
          @media screen and (max-width: 1280px) {
            font-size: 12px;
            line-height: 18px;
            width: 85%;
          }

          @media screen and (max-width: 1024px) {
            font-size: 9px;
            line-height: 14.5px;
            padding: 0;
            height: 19px;
            margin-bottom: 10px;
          }

          &:hover {
            color: #1312dd;
            border-color: #1312dd;
          }
        }
      }
    }
  }

  .ProgramsMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    .programContainer {
      margin-top: 30px;
      padding-bottom: 100px;
      width: 67%;
      @media (max-width: 1024px){
        max-width: 656px;
        margin: 30px auto auto;
      }
      @media (max-width: 480px) {
        padding-bottom: 300px;
      }
      .heading {
        font-family: $font-family-Dark;
        font-size: 30px;
      }

      .tabContainer {
        :global(::before) {
          border: none;
        }

        span {
          font-family: $font-family-Regular;
        }

        :global(.ant-tabs-tabpane) {
          min-height: 230px;
        }

        :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
          color: #ff1616;
          font-size: 20px;
          font-family: $font-family-Dark;
          @media (max-width: 1024px){
            font-size: 16px;
          }
        }

        :global(.ant-tabs-tab-btn) {
          color: #130f40;
          font-family: $font-family-Regular;
          font-size: 20px;
          @media (max-width: 1024px){
            font-size: 16px;
          }
        }

        :global(.ant-tabs-ink-bar) {
          margin-bottom: 5px;
          border-bottom: 2px solid #ff5230;
        }
      }
    }
  }

  .informationContainer {
    //border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-bottom: 40px;
    width: 68.5%;
    @media (max-width: 1024px){
      margin-top: 40px;
      max-width: 656px;
    }
  }
}
