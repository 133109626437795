$font-family: 'Poppins', sans-serif;
$font-family-Light: 'Poppins-Light';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: -72px;
  }

  .formContainer {
    padding: 30px;
    width: 100%;
    max-width: 618px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);

    @media (max-width: 1366px) {
      width: 90%;
    }

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 656px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      width: 90%;
      max-width: 656px;
    }

    @media (max-width: 640px) {
      width: 90%;
      padding: 20px 17px;
    }

    .formInput {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row;

      @media (max-width: 576px) {
        margin-top: 0;
      }

      :global(.ant-form) {
        width: 100%;
      }

      :global(.ant-row) {
        flex-direction: column;
      }

      :global(.ant-form-item) {
        margin: 10px 1px 5px 1px;
      }

      :global(.ant-form-item-label) {
        :global(label) {
          font-size: 16px;
        }
      }

      :global(.ant-input-affix-wrapper) {
        border: 1px solid #130f40;
        border-radius: 10px;
      }

      :global(.ant-btn-primary) {
        margin-top: 20px;
        width: 100%;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #232323;
        border-color: $btn-color;
        height: 43px;

        @media (max-width: 425px) {
          height: 32px;
        }

        &:hover {
          color: #1312dd;
          border-color: #1312dd;
        }
      }

      :global(.ant-input-suffix) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(168, 155, 155, 0.88);
      }

      :global(.ant-form-item-explain-error) {
        text-align: left;
      }
    }
  }
}

