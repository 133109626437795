$font-family: 'Poppins', sans-serif;
$font-style: normal;
$font-weight: 400;
$bg-img-desktop: url('../../assets/images/picture_desktop.jpg');
$bg-img-tablet: url('../../assets/images/picture_tablet.jpg');

.main {
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    height: unset;
    min-height: 100vh;
  }
  .header {
    width: 50%;
    max-height: 100vh;
    background-image: $bg-img-desktop;
    @media screen and (max-width: 1024px) {
      background-image: $bg-img-tablet;
      flex-direction: column;
      width: 100%;
      height: 300px;
    }

    .icon {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 50px;
      margin-right: 50px;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .iconText {
      display: flex;
      flex-direction: row-reverse;
      color: #000000;
      font-family: $font-family;
      font-style: $font-style;
      font-weight: $font-weight;
      font-size: 14px;
      line-height: 20px;
      margin-right: 55px;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
