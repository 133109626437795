$font-family-Regular: 'Poppins-Regular';
$font-family-Light: 'Poppins-Light';

.tabInformation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2%;
  @media (min-width: 480px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
  .tabDetail {
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    padding: 15px 1px 1px 1px;
    border-radius: 10px;
    background: #ffffff;
    min-height: 219px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    // cursor: pointer;
    .tabHeading {
      font-size: 15px;
      color: #232323;
      font-family: $font-family-Regular;
    }
  }
  .buttonContainer {
    :global(.ant-btn-primary) {
      // margin-top: 10px;
      width: fit-content;
      background: #f6ef25;
      border-radius: 100px;
      font-family: $font-family-Light;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #232323;
      border-color: #f6ef25;
      @media (max-width: 1280px) {
        font-size: 12px;
        line-height: 18px;
        // width: fit-content;
      }
      @media (max-width: 1024px) {
        font-size: 11px;
        line-height: 12.5px;
        height: 24px;
      }

      &:hover {
        color: #1312dd;
        border-color: #1312dd;
      }
    }
  }
}
