$font-family: 'Poppins', sans-serif;
$font-family-Light: 'Poppins-Light';
$font-family-Regular: 'Poppins-Regular';
$font-family-Dark: 'Poppins-Dark';
$font-style: normal;
$font-weight: 400;
$btn-color: #ff1616;
.WelcomeText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $font-family-Regular;
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: 40px;
  line-height: 60px;
  @media (max-width: 992px) {
    margin-top: 12px;
  }
  @media (max-width: 576px) {
    font-size: 30px;
    line-height: 45px;
  }
}

.selectForm {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  @media (max-width: 1024px) {
    margin-top: 10px;
  }

  .signup {
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 25px;
    line-height: 38px;
    cursor: pointer;
    @media (max-width: 576px){
      font-size: 20px;
      line-height: 30px;
    }
  }
  .activeSignup {
    font-family: $font-family-Dark;
    font-style: $font-style;
    font-weight: 300;
    font-size: 25px;
    line-height: 38px;
    cursor: pointer;
    color: $btn-color;
    @media (max-width: 576px){
      font-size: 20px;
      line-height: 30px;
    }
  }

  .or {
    margin: -1px 25px 0px 25px;

    span {
      color: #130f40;
      transform: rotate(-90deg);
      height: 1px;
      font-size: 25px;
    }
  }

  .logIn {
    height: 38px;
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 25px;
    line-height: 38px;
    cursor: pointer;
    @media (max-width: 576px){
      font-size: 20px;
      line-height: 30px;
    }
  }
  .activeLogin {
    height: 38px;
    font-family: $font-family-Dark;
    font-style: $font-style;
    font-weight: 300;
    font-size: 25px;
    line-height: 38px;
    cursor: pointer;
    color: $btn-color;
    @media (max-width: 576px){
      font-size: 20px;
      line-height: 30px;
    }
  }
}
