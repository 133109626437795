$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$title-color: #130f40;
$text-color: #000000;
.headerContainer {
  min-height: 450px;
  background: #1312dd;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px){
    min-height: 360px;
  }

  .headerMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    @media (max-width: 576px){
      align-items: flex-start;
      padding: 0 16px;
    }

    .cogPlayIcon {
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      color: #ffffff;
      font-family: $font-family;
      font-style: normal;
      font-weight: $font-weight;
      font-size: 8px;
      line-height: 12px;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .headingText {
      font-family: $font-family-Regular;
      font-style: normal;
      font-weight: $font-weight;
      font-size: 40px;
      line-height: 60px;
      color: #ffffff;
      margin: 10px 0;
      @media (max-width: 576px) {
        text-align: left;
        margin-top: 10px;
        font-size: 30px;
        line-height: 1.5;
      }
    }

    .headingSmallText {
      font-family: $font-family-Regular;
      font-style: normal;
      font-weight: $font-weight;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      @media (max-width: 576px) {
        font-size: 16px;
        text-align: left;
      }
    }

    .activeButtonClass {
      :global(.ant-btn-primary) {
        margin-top: 8px;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        border-color: $btn-color;
        width: 15%;
        margin-bottom: 20px;
        @media  (max-width: 650px) {
          width: 30%;
        }
      }
    }

    .buttonClass {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      :global(.ant-btn-primary) {
        margin-top: 8px;
        background: white;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        border-color: $btn-color;
        width: 15%;
        margin-bottom: 20px;
        @media  (max-width: 650px) {
          width: 30%;
        }
      }

      :global(.ant-btn-default) {
        margin-top: 8px;
        background: white;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        border-color: gray;
        width: 306px;
        margin-top: 20px;
        @media  (max-width: 650px) {
          width: 206px;
        }
      }
    }

    .profileButtonClass {
      display: flex;
      justify-content: center;
      gap: 2%;
      @media (max-width: 430px){
        flex-wrap: wrap;
      }
      .addButtonClass {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        flex-direction: column;
        font-family: $font-family-Medium;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        @media (max-width: 430px){
          font-size: 10px;
        }
        :global(.ant-btn-default) {
          display: flex;
          align-items: center;
          justify-content: center;
          //margin-top: 8px;
          background: #f6ef25;
          border-radius: 100%;
          height: 45px;
          width: 45px;
          border-color: #f6ef25;
          color: #ffffff;
          font-weight: 300;
          font-size: 40px;
          line-height: 24px;
          @media  (max-width: 650px) {
            width: 35px;
            height: 35px;
            font-size: 18px;
          }
        }
        :global(.ant-btn-primary) {
          display: flex;
          align-items: center;
          justify-content: center;
          //margin-top: 8px;
          background: #f6ef25;
          border-radius: 100%;
          height: 45px;
          width: 45px;
          border-color: #f6ef25;
          color: #000000;
          font-weight: 300;
          font-family: $font-family-Dark;
          font-size: 22px;
          line-height: 33px;
          @media  (max-width: 650px) {
            width: 35px;
            height: 35px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.tabContainer {
  min-height: 67px;
  display: flex;
  justify-content: flex-end;
  background-color: #f6ef25;
  align-items: center;

  .tabText {
    display: flex;
    margin-right: 15%;
    padding: 20px 1px 5px 1px;
    @media (max-width: 768px){
      margin-right: 5%;
    }
    @media  (max-width: 500px) {
      display: none;
    }

    .loginTab {
      height: 5px;
      padding: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 30px;
      cursor: pointer;
    }

    .profileIcon {
      width: 22%;
      height: 5px;
      padding: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 30px;
      margin-left: -30px;
    }
    .logoutIcon {
      height: 5px;
      padding: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      margin-left: -20px;
      cursor: pointer;
      color: #232323;
    }
  }

  .manuContainer {
    display: none;
    @media  (max-width: 500px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      // text-align: right;
      padding: 0 25px;
    }
  }
}

.drawerClass {
  :global(.ant-drawer-close) {
    color: red;
    font-size: 25px !important;
  }

  .hederContainer {
    text-align: center;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    margin-bottom: 40px;

    .cogPlayIcon {
      margin: auto;
      padding: auto;
      display: block;
    }
  }
}

.drawerLogout {
  font-family: $font-family-Regular;
  font-style: normal;
  color: #232323;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}

:global(.ant-drawer-content-wrapper) {
  @media (max-width: 576px){
    width: 330px !important;
  }
  @media (max-width: 375px){
    width: 300px !important;
  }
}