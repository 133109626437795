$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 0 35px;
  @include contentHeight;


  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 63%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (min-width: 700px) and (max-width: 1250px) {
      width: 80%;
    }
    @media (min-width: 500px) and (max-width: 700px) {
      width: 90%;
    }
    @media (min-width: 200px) and (max-width: 510px) {
      box-shadow: none;
      width: 100%;
    }

    .instruction {
      width: 65%;
      text-align: center;
      font-family: $font-family;
      font-size: 22px;
      @media (min-width: 700px) and (max-width: 1250px) {
        font-size: 20px;
        width: 70%;
      }
    }

    .quizWrapper {
      .quizContent {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        border: 1px solid #232323;
        border-radius: 5px;
        @media (min-width: 200px) and (max-width: 700px) {
          grid-template-columns: repeat(9, 1fr);
        }
        .numberContent {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-family;
          font-weight: 400;
          width: 60px;
          height: 40px;
          font-size: 20px;
          border: 1px solid #232323;
          @media (min-width: 700px) and (max-width: 1250px) {
            width: 40px;
            height: 35px;
          }
          @media (min-width: 200px) and (max-width: 700px) {
            width: 40px;
            height: 35px;
          }
        }
      }
    }
  }
}
