$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
$border-color: #ff0000;
$fontBlack: #130f40;
$bg-img: url('../../../assets/images/HomePage/Programs/Figure8/figure_8.svg');
$bg-figure8-yellow: url('../../../assets/images/HomePage/Programs/Figure8/figure8_yellow.svg');
$bg-figure8-blue: url('../../../assets/images/HomePage/Programs/Figure8/figure8_blue.svg');
@use '../../../assets/scss/common' as *;

.mainContainer {
  min-height: 776px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto ;
  padding-bottom: 40px;
  width: 100%;  
  @include contentHeight;

  .modalContainer {
    overflow: hidden;
    display: flex;
    row-gap: 15px;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    margin-top: 40px;
    @media (min-height: 1000px){
      margin-top: 0;
    }
    @media (max-width: 650px){
      width: 85%;
    }
    @media (max-width: 500px) {
      box-shadow: none;
    }

    .instruction {
      width: 65%;
      height: 40px;
      font-family: $font-family;
      font-size: 20px;
      @media screen and (max-width: 1365px) {
        font-size: 18px;
      }
      @media screen and (max-width: 840px) {
        font-size: 14px;
      }
      @media screen and (max-width: 530px) {
        font-size: 11px;
      }
    }
    .headerText {
      font-family: $font-family-Dark;
      color: #ff0000;
      margin-top: 35px;
      font-size: 25px;
      @media (max-width: 1250px) {
        font-size: 20px;
      }
    }
    .quizWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .informationWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px){
          flex-direction: column;
        }

        .descWrapper {
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          //align-items: center;
          text-align: left;
          font-size: 20px;
          line-height: 30px;
          font-family: $font-family-Regular;
          padding: 80px;
          @media (max-width: 1024px){
            padding: 25px;
          }
          @media (max-width: 768px){
            width: 100%;
            padding: 25px;
          }
        }
      }

      .yellowArrowWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;
        width: 350px;
        background-image: $bg-figure8-yellow;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 280px 280px;
        margin-top: 40px;

        .eight {
          position: absolute;
          max-width: fit-content;
        }

        .canvas {
          background-color: transparent;
          z-index: 1;

          .results {
            display: none;
          }
        }

        .toolTipText {
          visibility: hidden;
          width: fit-content;
          background-color: white;
          color: $fontBlack;
          text-align: center;
          white-space: nowrap;
          border-radius: 10px;
          border: 1px solid $border-color;
          padding: 10px;
          position: absolute;
          z-index: 1;
        }

        .toolTipText::before {
          content: '';
          position: absolute;
          left: 75px;
          bottom: 42px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent $border-color transparent transparent;
          transform: rotate(93deg);
        }
      }

      .blueArrowWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;
        width: 350px;
        // background-image: $bg-figure8-blue;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 280px 280px;
        margin-top: 40px;

        .eight {
          position: absolute;
          max-width: fit-content;
        }

        .canvas {
          background-color: transparent;
          z-index: 1;

          .results {
            display: none;
          }
        }

        .toolTipText {
          visibility: hidden;
          width: fit-content;
          background-color: white;
          color: $fontBlack;
          text-align: center;
          white-space: nowrap;
          border-radius: 10px;
          border: 1px solid $border-color;
          padding: 10px;
          position: absolute;
          z-index: 1;
        }

        .toolTipText::before {
          content: '';
          position: absolute;
          left: 75px;
          bottom: 42px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent $border-color transparent transparent;
          transform: rotate(93deg);
        }
      }
      .redArrowWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;
        width: 350px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 280px 280px;
        margin-top: 10px;

        .eight {
          position: absolute;
          max-width: fit-content;
        }

        .canvas {
          background-color: transparent;
          z-index: 1;

          .results {
            display: none;
          }
        }

        .toolTipText {
          visibility: hidden;
          width: fit-content;
          background-color: white;
          color: $fontBlack;
          text-align: center;
          white-space: nowrap;
          border-radius: 10px;
          border: 1px solid $border-color;
          padding: 10px;
          position: absolute;
          z-index: 1;
        }

        .toolTipText::before {
          content: '';
          position: absolute;
          left: 75px;
          bottom: 42px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent $border-color transparent transparent;
          transform: rotate(93deg);
        }
      }
    }
  }
}


.overflow-hidden {
  overflow-y: hidden !important;
}
.closeButton {
  cursor: pointer;
  :global(.ant-btn) {
    pointer-events: none;
    background: #5858fe;
    border-radius: 10px;
    font-family: $font-family-Dark;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    height: 40px;
    @media (max-width: 650px) {
      width: 90%;
    }

    &:hover,
    &:focus,
    &:active {
      background: #5858fe;
      color: #ffffff;
    }
  }
}
.buttonContainer{
  display: flex;
  gap: 20px;
}
