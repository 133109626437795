$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  .textWrapper {
    font-family: $font-family-Regular;
    color: #ffffff;
    line-height: 22px;
    font-size: 16px;
    @media (max-width: 1350px) {
      font-size: 14px;
    }
    @media (max-width: 500px) {
      font-size: 10px;
      line-height: 18px;
      width: 100%;
    }
    .linkWrapper {
      margin-top: -15px;
      font-family: $font-family-Medium;
      color: #232323;
      font-size: 16px;
      margin-bottom: -10px;
      @media (max-width: 500px) {
        font-size: 12px;
        margin-top: 10px;
      }
      a {
        color: #232323;
      }
    }
  }
  .headingContainer {
    font-family: $font-family-Extra-Dark;
    color: #ffffff;
    font-size: 40px;
    @media (max-width: 1350px) {
      font-size: 35px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
      margin-top: 10px;
    }
  }
  .videoWrapper {
    padding-bottom: 10px;
    img {
      height: 270px;
      @media (max-width: 500px) {
        height: 250px;
        width: 100%;
      }
      @media (max-width: 360px) {
        height: 200px;
      }
    }
  }
  .textWrapper1 {
    font-family: $font-family-Regular;
    color: #ffffff;
    line-height: 22px;
    font-size: 16px;
    a {
      font-family: $font-family-Medium;
      color: #232323;
      font-size: 11px;
      margin-bottom: -10px;
    }
  }
}
