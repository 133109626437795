$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Dark: 'Poppins-Extra-Dark';
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;

  .headingContainer {
    font-size: 20px;
    margin: 10px 0;
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: 475px) {
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }
    .cancelButton {
      :global(.ant-btn) {
        background: red;
        color: white;
      }
    }

    :global(.ant-btn) {
      background: #f6ef25;
      border-radius: 100px;
      font-family: $font-family-Medium;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #232323;
      margin-top: 20px;
      height: 43px;
      width: 190px;
      border: none;
      @media (max-width: 475px) {
        width: 100%;
      }
    }
  }
}
