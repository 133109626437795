$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$background-Image: url('../../../assets/images/HomePage/Programs/Result/resultBg.png');

.resultModalStyle {
  width: 90% !important;
  max-width: 1266px !important;
  .containerWrapper {
    background-image: $background-Image;
    background-repeat: no-repeat;
    background-size: cover;
    height: 753px;

    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      text-align: center;
      font-family: $font-family-Medium;
      font-size: 30px;
      @media (max-width: 576px){
        padding-right: 10px;
      }

      .headerText {
        width: 100%;
      }

      .closeIcon {
        cursor: pointer;
        padding-right: 20px;
        @media (max-width: 576px){
          padding-right: 0;
          svg{
            height: 20px;
          }
        }
      }
    }

    .mainContainer {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 2%;

      .imageWrapper {
        padding-left: 40px;
        width: 68%;
      }

      .resultWrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        color: #ffffff;
        width: 32%;

        .timeWrapper {
          font-size: 30px;
          line-height: 45px;
          font-family: $font-family-Medium;
        }
        .backWrapper {
          font-size: 15px;
          line-height: 18px;
          font-family: $font-family-Medium;
        }

        .greetingWrapper {
          font-size: 20px;
          line-height: 30px;
          width: 75%;
          font-family: $font-family-Regular;
        }

        .resultButton {
          :global(.ant-btn-primary) {
            white-space: normal;
            word-wrap: break-word;
            margin-top: 10px;
            height: fit-content;
            background: #1312dd;
            border-radius: 10px;
            font-family: $font-family-Light;
            font-style: normal;
            font-weight: 300;
            font-size: 23px;
            line-height: 38px;
            text-align: center;
            border-color: #1312dd;
            margin-bottom: 20px;
            padding: 10px;
            &:hover {
              border-color: #f6ef25;
            }
          }

          :global(.ant-btn-primary[disabled]) {
            white-space: normal;
            word-wrap: break-word;
            color: #ffffff;
            margin-top: 10px;
            height: fit-content;
            background: #1312dd;
            border-radius: 10px;
            font-family: $font-family-Light;
            font-style: normal;
            font-weight: 300;
            font-size: 23px;
            line-height: 38px;
            text-align: center;
            border-color: #1312dd;
            margin-bottom: 20px;
            padding: 10px;
          }
        }
      }

      .finalResultWrapper {
        color: #ffffff;

        .greetingWrapper {
          font-size: 30px;
          line-height: 45px;
          width: 86%;
          font-family: $font-family-Regular;
        }

        .resultButton {
          :global(.ant-btn-primary) {
            white-space: normal;
            word-wrap: break-word;
            margin-top: 10px;
            height: fit-content;
            width: 80%;
            background: #1312dd;
            border-radius: 10px;
            font-family: $font-family-Light;
            font-style: normal;
            font-weight: 300;
            font-size: 23px;
            line-height: 38px;
            text-align: center;
            border-color: #1312dd;
            margin-bottom: 20px;

            &:hover {
              border-color: #f6ef25;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1250px) {
  .resultModalStyle {
    .containerWrapper {
      .mainContainer {
        .resultWrapper {
          .timeWrapper {
            font-size: 26px;
          }

          .greetingWrapper {
            font-size: 18px;
          }

          .resultButton {
            :global(.ant-btn-primary) {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .resultModalStyle {
    .containerWrapper {
      height: 795px;

      .mainContainer {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .imageWrapper {
          img {
            width: 700px;
            margin-right: 40px;
          }
        }

        .resultWrapper {
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          .greetingWrapper {
            width: 100%;
          }
        }

        .finalResultWrapper {
          text-align: center;

          .greetingWrapper {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .resultModalStyle {
    .containerWrapper {
      height: 660px;

      .mainContainer {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .imageWrapper {
          padding: 0 20px;
          img {
            width: 500px;
            margin-right: 40px;
          }
        }

        .resultWrapper {
          text-align: center;
          justify-content: center;
          align-items: center;
          width: 100%;
          .timeWrapper {
            font-size: 25px;
          }

          .greetingWrapper {
            font-size: 17px;
            width: 100%;
          }

          .resultButton {
            :global(.ant-btn-primary) {
              font-size: 18px;
            }
          }
        }

        .finalResultWrapper {
          text-align: center;

          .timeWrapper {
            font-size: 25px;
          }

          .greetingWrapper {
            font-size: 17px;
            width: 100%;
          }

          .resultButton {
            :global(.ant-btn-primary) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .resultModalStyle {
    .containerWrapper {
      height: 660px;

      .mainContainer {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .imageWrapper {
          padding: 0 20px;
          img {
            width: 300px;
            margin-right: 40px;
          }
        }

        .resultWrapper {
          padding-top: 50px;
          text-align: center;
          justify-content: center;
          align-items: center;
          width: 100%;
          .timeWrapper {
            font-size: 25px;
          }

          .greetingWrapper {
            padding-top: 10px;
            font-size: 17px;
            width: 100%;
          }

          .resultButton {
            :global(.ant-btn-primary) {
              font-size: 16px;
            }
          }
        }

        .finalResultWrapper {
          padding-top: 50px;
          text-align: center;

          .timeWrapper {
            font-size: 25px;
          }

          .greetingWrapper {
            padding-top: 10px;
            font-size: 17px;
            width: 100%;
          }

          .resultButton {
            :global(.ant-btn-primary) {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}
