$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-style: normal;
$font-weight: 400;
$title-color: #130f40;
$text-color: #000000;
.profileContainer {
  padding: 54px 0;
  // height: 473px;
  display: flex;
  justify-content: center;
  // @media (min-width: 200px) and (max-width: 1200px) {
  //   height: 630px;
  // }
  @media (min-width: 200px) and (max-width: 1200px) {
    align-items: center;
  }
  // @media (min-width: 200px) and (max-width: 650px) {
  //   height: 3000px;
  //   margin-top: -1200px;
  // }

  .subContainer {
    // height: 370px;
    // bottom: 18.37%;
    background: #ffffff;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    // @media (min-width: 200px) and (max-width: 1200px) {
    //   height: 450px;
    // }
    @media  (max-width: 992px) {
      width: 90%;
    }
    @media  (max-width: 650px) {
      width: 100%;
      box-shadow: none;
    }

    .settings {
      font-family: $font-family-Regular;
      font-style: $font-style;
      font-weight: $font-weight;
      font-size: 40px;
      line-height: 60px;
      padding-top: 10px;
      color: $title-color;
      @media  (max-width: 450px) {
        margin-top: 0px;
      }
    }

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      height: 280px;
      padding: 15px 60px 1px 60px;
      margin-bottom: 20px;

      @media (min-width: 200px) and (max-width: 1200px) {
        height: 360px;
      }
      @media (max-width: 950px) {
        height: 360px;
        grid-template-columns: 1fr 1fr;
      }
      @media  (max-width: 650px) {
        grid-template-columns: 1fr;
        padding: 15px 30px 1px;
        height: auto;
      }

      .subComponentClass {
        max-width: 180px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 4px 14px rgba(19, 15, 64, 0.4);
        flex-direction: column;
        margin: 12px;
        font-family: $font-family-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        border: 2px solid #ffffff;

        &:hover {
          border: 2px solid #ff1616;
        }

        @media (min-width: 200px) and (max-width: 1200px) {
          max-width: 180px;
          font-size: 25px;
          line-height: 35px;
        }
        @media  (max-width: 950px) {
          max-width: 325px;
        }
        @media (min-width: 200px) and (max-width: 650px) {
          max-width: 100%;
          height: 350px;
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
  }
  .buttonWrapper {
    width: 100%;
    padding: 0 60px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 650px){
      padding: 0 35px;
    }

    @media (max-width: 475px){
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }

    :global(.ant-btn) {
      background: #f6ef25;
      border-radius: 100px;
      font-family: $font-family-Medium  ;
      font-style: $font-style;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #232323;
      margin-top: 20px;
      height: 43px;
      width: 190px;
      border: none;
      @media (max-width: 475px){
        width: 100%;
      }
    }
    .logoutButton{
      :global(.ant-btn) {
        background: red;
        color: white;
      }
    }
  }
}
