$font-family: 'Poppins', sans-serif;

.root {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
  width: 100%;
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232323;

  &.inputVerticalWrapper {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left !important;
    }
  }

  .inputClass {
    border: 1px solid #130f40;
    border-radius: 7px;
    box-shadow: none !important;
  }
}
