$font-family-Regular: 'Poppins-Regular';

.root {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
  width: 100%;
  font-family: $font-family-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232323;

  &.inputVerticalWrapper {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left !important;
    }
  }

  .inputClass {
    text-align: left;
    width: 130px;
    border: 1px solid #130f40;
    font-size: 100px;
    box-shadow: none !important;
    line-height: 120px;

    &:hover {
      border-color: #ff1616;
      border-right-width: 1px;
      z-index: 1;
    }
    input {
      border-radius: 0;
      width: 125px;
      height: 120px;
      padding: 0;
      text-align: right;
      font-weight: 900;
    }
    input::placeholder {
      color: red; /* Replace "red" with the desired color */
    }
  }

  .messCls {
    color: #232323;
    font-family: $font-family-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
  }
}
