$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-style: normal;
.homeContainer {
  @media (max-width: 992px) {
    max-width: 90%;
    min-height: 400px;
  }
  min-height: 511px;
  margin: -170px 30px 50px;
  gap: 5px;
  display: flex;
  justify-content: center;
  @media (max-width: 700px) {
    display: block;
  }
  @media (max-width: 600px) {
    margin: -70px auto 50px;
  }

  .parents {
    flex: 1;
    max-width: 700px;
    margin-left: 30px;
    min-height: 560px;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 992px) {
      max-height: 10%;
      min-height: 406px;
    }
    @media (max-width: 700px) {
      margin-bottom: 20px;
      margin-left: 0;
      min-height: 430px;
    }
    @media (max-width: 576px) {
      min-height: 370px;
    }

    .parentHeading {
      font-family: $font-family-Dark;
      font-style: $font-style;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      text-align: center;
      color: #130f40;

      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 30px;
      }

      .child {
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #2c2c2c;
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .parentContainer {
      // height: 50%;
      width: 100%;
      margin-top: 15px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      @media (max-width: 992px) {
        height: 50%;
      }

      @media (max-width: 576px) {
        flex-wrap: wrap;
      }
      @media (max-width: 360px) {
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
      }

      .priceContainer {
        width: 43%;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 4px 14px rgba(19, 15, 64, 0.4);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid #ffffff;
        @media (max-width: 992px) {
          width: 50%;
          padding: 10px;
          margin-bottom: 10px;
        }
        @media (max-width: 576px) {
          width: 45%;
          padding: 10px;
          margin-bottom: 10px;
        }

        @media (max-width: 360px) {
          width: 50%;
        }

        &:hover {
          cursor: pointer;
          border: 2px solid #ff1616;
        }
        .priceHeading {
          width: 47.4%;
          height: 25%;
          margin-top: 20px;
          font-family: $font-family-Regular;
          font-style: $font-style;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          color: #232323;
          display: flex;
          justify-content: center;
          @media (max-width: 992px) {
            height: 30%;
            font-size: 15px;
            line-height: 15px;
            margin-top: 0;
            margin-bottom: 10px;
          }
          @media (max-width: 576px) {
            margin-top: 0;
            margin-bottom: 10px;
          }
        }

        .buttons {
          display: flex;
          justify-content: center;
          :global(.ant-btn) {
            box-sizing: border-box;
            border: 2px solid #f6ef25;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family-Medium;
            font-style: $font-style;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #232323;
            width: 90%;
            padding: 20px 40px;
            margin-top: 20px;
            height: 40%;
            @media (max-width: 992px) {
              margin-top: 5px;
              width: 85%;
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
              padding: 15px 40px;
            }
            @media (max-width: 360px) {
              padding: 8px 40px;
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }

          :global(.ant-btn-primary) {
            box-sizing: border-box;
            border: 2px solid #f6ef25;
            background: #ffffff;
            border-radius: 50px;
            font-family: $font-family-Medium;
            font-style: $font-style;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #232323;
            width: 90%;
            margin-top: 20px;
            height: 40%;
            @media (max-width: 992px) {
              margin-top: 5px;
              width: 85%;
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
            }
          }

          :global(.ant-btn):hover {
            background: #f6ef25;
          }
        }

        .activeButton1 {
          :global(.ant-btn) {
            background: #f6ef25;
          }

          :global(.ant-btn-primary) {
            background: #ffffff;
          }
        }

        .activeButton2 {
          :global(.ant-btn-primary) {
            background: #f6ef25;
          }

          :global(.ant-btn):hover {
            background: #f6ef25;
          }
        }
      }

      .priceContainer:focus {
        box-shadow: 0px 4px 14px rgba(19, 15, 64, 0.4);
        border: 2px solid #ff1616;
      }
    }

    .becomeButton {
      width: 95%;

      :global(.ant-btn) {
        background: #f6ef25;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #232323;
        margin-top: 20px;
        height: 43px;
        width: 97%;
        border: none;
      }
    }

    .parentFooter {
      padding: 0px 17px 0px 26px;
      margin-top: 15px;
      // margin-bottom: -15px;
      // width: 95%;
      font-family: $font-family-Light;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 23px;
      text-align: left;
      letter-spacing: 0.15em;
      font-stretch: 50%;

      @media (max-width: 576px) {
        line-height: 12px;
        span {
          font-size: 8px;
        }
      }

      a {
        color: #1312dd;
      }
    }
  }
}
