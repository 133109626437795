$font-family: 'Poppins', sans-serif;
$font-family-Light: 'Poppins-Light';
$font-family-Regular: 'Poppins-Regular';
$font-style: normal;
$font-weight: 400;
.footer {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-bottom: 3%;
  .footerOr {
    display: flex;
    width: 100%;

    hr {
      width: 40%;
      background: #130f40;
      border-radius: 1px;
      margin-top: 6%;
    }

    span {
      font-family: $font-family-Light;
      font-style: $font-style;
      font-weight: 300;
      font-size: 23px;
      line-height: 30px;
      margin-top: 4%;
    }
  }
}

.socialMedia {
  margin-top: 4%;
  font-family: $font-family;
  font-style: $font-style;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  .displayText {
    margin-left: 10px;
  }
  @media screen and (max-width: 650px) {
    .displayText {
      display: none;
    }
  }

  :global(.ant-btn) {
    width: 212px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 16px;
    height: 43px;
  }
  .FacebookLogin {
    min-width: 212px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border: 1px solid white;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 16px;
    height: 43px;
    cursor: pointer;
    &:hover {
      border-color: #40a9ff;
      color: #40a9ff;
    }
  }

  img {
    padding-right: 10px;
  }
}
