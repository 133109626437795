$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
.subHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  font-family: $font-family-Regular;
}

.questionContainer {
  display: flex;

  .detailContainer {
    width: 100%;
    flex-direction: column;

    .number1 {
      height: 130px;
      width: 130px;
      margin-left: 80px;
    }

    .number11 {
      height: 130px;
      width: 130px;
    }

    .number2 {
      .signContainer {
        font-size: 100px;
        color: #ff1616;
      }
    }

    .answer {
      hr {
        border: 2px solid #130f40;
        width: 90%;
      }

      :global(.Input_inputWrapper__faV4N .Input_inputClass__dfqtd) {
        margin-left: 40px;
        border: 1px solid #130f40;
        border-radius: 1px;
        width: 120px;
        padding: 2px;
        height: 120px;
        font-family: $font-family-Dark;
        font-size: 110px;
        text-align: right;
        color: #130f40;
        cursor: pointer;
      }
    }
  }
}
