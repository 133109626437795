$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
@use '../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 35px;

  @media (min-height: 1000px){
    height: calc(100vh - 213px);
  }

  @media (max-width: 576px){
    padding: 25px;
  }


  .modalContainer {
    display: flex;
    row-gap: 15px;
    width: 63%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    border-radius: 10px;
    @media (min-width: 700px) and (max-width: 1250px) {
      width: 70%;
    }
    @media (min-width: 500px) and (max-width: 700px) {
      width: 94%;
    }
    @media (min-width: 200px) and (max-width: 500px) {
      box-shadow: none;
      width: 100%;
    }
    .instruction {
      width: 65%;
      text-align: center;
      font-family: $font-family;
      font-size: 22px;
      @media (max-width: 1024px) {
        font-size: 16px;
        max-width: 365px;
        width: 100%;
      }
      @media (max-width: 576px) {
        max-width: 80%;
        line-height: 19px;
        margin-bottom: 10px;
      }
      @media (max-width: 425px) {
        max-width: 100%;
      }
    }

    .quizWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 40px;
      @media (min-width: 500px) and (max-width: 1250px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
      }
      @media (min-width: 200px) and (max-width: 510px) {
        flex-direction: column-reverse;
      }
      .quizController {
        display: flex;
        flex-direction: column;
        row-gap: 60px;
        align-items: center;
        @media (max-width: 1250px) {
          padding-top: 30px;
          display: flex;
          flex-direction: row;
          gap: 60px;
        }
        @media (max-width: 650px) {
          width: 100%;
          justify-content: space-between;
          padding-right: 20px;
        }
        .color {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
          @media  (max-width: 500px){
            row-gap: 20px;
          }

          .btnGroup {
            display: flex;
            column-gap: 30px;
            @media  (max-width: 500px){
              column-gap: 20px;
            }

            .btn {
              height: 60px;
              width: 60px;
              @media  (max-width: 500px){
                height: 40px;
                width: 40px;
              }
            }
            .selectedBtn {
              transform: scale(1.2);
            }

            .btnDisabled {
              pointer-events: none;
              transform: scale(1.2);
              box-shadow: 1px 4px 14px rgb(19 15 64 / 40%);
              border-radius: 5px;
            }

            .activeArrow {
              transform: scale(1.2);
            }

            .btnPointer {
              cursor: pointer;
            }
          }
        }

        .direction {
          display: flex;
          flex-direction: column;
          width: 'fit-content';
          transform: rotate(45deg);
          row-gap: 20px;
          .btnGroup {
            display: flex;
            column-gap: 20px;

            .btn {
              height: 50px;
              width: 50px;
              transform: rotate(-45deg);
              @media  (max-width: 500px){
                height: 35px;
                width: 35px;
              }
            }

            .selectedBtn {
              transform: scale(1.2) rotate(-45deg);
            }

            .activeArrow {
              width: 55px;
              filter: blur(0.5);
            }

            .btnPointer {
              cursor: pointer;
            }
          }
        }
      }

      .quizBody {
        padding: 10px;
        border: 2px solid #f6ef25;
        border-radius: 10px;
        // @media (min-width: 350px) and (max-width: 510px) {
        //   // width: 410px;
        // }
        .alignRow {
          margin-bottom: 10px;

          @media (max-width: 375px){
            margin-bottom: 0;
          }

          .alignImg {
            padding: 5px;
            height: 75px;

            @media (min-width: 850px) and (max-width: 950px) {
              width: 50px;
            }
            @media (min-width: 450px) and (max-width: 850px) {
              width: 38px;
              height: 50px;
            }
            @media (min-width: 375px) and (max-width: 450px) {
              width: 30px;
              height: 45px;
            }
            @media (min-width: 320px) and (max-width: 375px) {
              width: 27px;
              height: 35px;
            }
          }

          .align1Active {
            border: 2px solid #2e5ca7;
          }

          .align2Active {
            border: 2px solid #e52812;
          }

          .align3Active {
            border: 2px solid #efe40c;
          }

          .align4Active {
            border: 2px solid #1fa238;
          }
        }
      }
    }
  }
}
.closeButton {
  cursor: pointer;
  :global(.ant-btn) {
    pointer-events: none;
    background: #5858fe;
    border-radius: 10px;
    font-family: $font-family-Dark;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    height: 40px;
    @media (max-width: 650px) {
      width: 90%;
    }

    &:hover,
    &:focus,
    &:active {
      background: #5858fe;
      color: #ffffff;
    }
  }
}