$font-family-Light: 'Poppins-Light';
$font-family-Bold: 'Domine-Bold';
$font-family-Medium: 'Domine-Medium';
$font-family-Regular: 'Domine-Regular';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$title-color: #130f40;
$text-color: #000000;
@use '../../../../assets/scss/common' as *;

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 35px 0;
  
  @media (max-width: 650px) {
    height: calc(100vh - 213px);
    overflow: auto;
    justify-content: flex-start;
  }
  @media (max-width: 470px) {
    height: calc(100vh - 315px);
  }

  .termsContainer {
    max-width: 70%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(19, 15, 64, 0.4);
    padding-bottom: 30px;
    @media (max-width: 992px) {
      max-width: 90%;
    }
    @media (max-width: 650px) {
      max-width: 90%;
      max-height: 390px;
      justify-content: flex-start;
    }

    .contentContainer {
      background: #ffffff;
      max-width: 85%;
      max-height: 80%;
      text-align: left;
      align-items: left;
      padding: 12px 30px;
      border: 1px solid #acacac;
      border-radius: 5px;
      overflow: auto;

      .titleClass {
        color: $text-color;
        font-family: $font-family-Bold;
        font-style: $font-style;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
      }
      p {
        font-size: 15px;
        font-family: $font-family-Medium;
      }
      .subContent {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-family: $font-family-Medium;
        .listDashed {
          list-style-type: '- ';
        }
      }
    }

    .buttonClass {
      flex-direction: column;
      width: 100%;
      padding-bottom: 30px;
      padding-top: 10px;

      :global(.ant-btn-primary) {
        margin-top: 20px;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        border-color: $btn-color;
        width: 30%;
        margin-left: 10px;
      }
      :global(.ant-btn-default) {
        margin-top: 20px;
        background: white;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        border-color: gray;
        width: 30%;
      }
    }
  }
}
