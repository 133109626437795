$font-family-Light: 'Poppins-Light';
$font-family-Medium: 'Poppins-Medium';
$font-family-Regular: 'Poppins-Regular';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$text-color: #000000;
.buttonClass {
  flex-direction: column;
  width: 100%;
  padding-top: 10px;

  :global(.ant-btn-primary) {
    margin-top: 20px;
    background: $btn-color;
    border-radius: 100px;
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #232323;
    border-color: $btn-color;
    width: 30%;
    margin-left: 10px;
  }
  :global(.ant-btn-default) {
    margin-top: 20px;
    background: white;
    border-radius: 100px;
    font-family: $font-family-Light;
    font-style: $font-style;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #232323;
    border-color: gray;
    width: 30%;
  }
}
