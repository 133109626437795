$brainModal-image: url('../../../assets/images/HomePage/InformationModal/brainModal.svg');
$eyeTrackingModal-image: url('../../../assets/images/HomePage/InformationModal/eyeTrackingModal.svg');
$teachingModal-image: url('../../../assets/images/HomePage/InformationModal/teachingModal.svg');
$fullBodyModal-image: url('../../../assets/images/HomePage/InformationModal/fullBodyModal.svg');
:global(.ant-modal-root) {
  .ant-modal-mask {
    background-color: rgba(35, 35, 35, 0.9);
    backdrop-filter: blur(3px);
  }
}
:global(.ant-modal-close-x) {
  margin-top: -60px;
  margin-left: -40px;
  position: absolute;
  color: #ffffff;
}
:global(.ant-modal-body) {
  margin: 0;
  padding: 0;
}
.brainModalStyle {
  :global(.ant-modal-content) {
    min-width: 620px;
    border-radius: 5px;
    background: rgba(247, 236, 60);
    // margin-left: -20px;
    padding: 0;
    @media (max-width: 768px){
      min-width: unset;
      width: 95%;
      margin: auto;
    }
  }
}
.eyeTrackingModalStyle {
  :global(.ant-modal-content) {
    min-width: 620px;
    border-radius: 5px;
    background: rgba(103, 211, 86);
    // margin-left: -20px;
    padding: 0;
    @media (max-width: 768px){
      min-width: unset;
      width: 95%;
      margin: auto;
    }
  }
}
.teachingModalStyle {
  :global(.ant-modal-content) {
    min-width: 600px;
    border-radius: 5px;
    background: rgba(247, 236, 60);
    // margin-left: -15px;
    padding: 0;
    @media (max-width: 768px){
      min-width: unset;
      width: 95%;
      margin: auto;
    }
  }
}

.fullBodyModalStyle {
  :global(.ant-modal-content) {
    min-width: 600px;
    border-radius: 5px;
    background: rgba(252, 55, 37);
    // margin-left: -15px;
    padding: 0;
    @media (max-width: 768px){
      min-width: unset;
      width: 95%;
      margin: auto;
    }
  }
}
.confirmationModalStyle {
  width: 90% !important;
  max-width: 520px !important;
  :global(.ant-modal-content) {
    padding: 10px 0;
    @media (max-width: 768px){
      width: 100%;
      margin: auto;
    }
  }
}
@media (max-width: 1000px) {
  .brain-ModalStyle .ant-modal-content {
    min-width: 550px;
  }
  .eyeTracking-ModalStyle .ant-modal-content {
    min-width: 550px;
  }
  .teaching-ModalStyle .ant-modal-content {
    min-width: 550px;
  }
  .fullBody-ModalStyle .ant-modal-content {
    min-width: 550px;
  }
}
@media (max-width: 650px) {
  .brain-ModalStyle .ant-modal-content {
    min-width: 500px;
  }
  .eyeTracking-ModalStyle .ant-modal-content {
    min-width: 500px;
  }
  .teaching-ModalStyle .ant-modal-content {
    min-width: 500px;
  }
  .fullBody-ModalStyle .ant-modal-content {
    min-width: 500px;
  }
}
@media (max-width: 500px) {
  .brain-ModalStyle .ant-modal-content {
    min-width: 350px;
  }
  .eyeTracking-ModalStyle .ant-modal-content {
    min-width: 350px;
  }
  .teaching-ModalStyle .ant-modal-content {
    min-width: 350px;
  }
  .fullBody-ModalStyle .ant-modal-content {
    min-width: 350px;
  }
}
@media (max-width: 360px) {
  .brain-ModalStyle .ant-modal-content {
    min-width: 280px;
    max-height: 500px;
    overflow: scroll;
  }
  .eyeTracking-ModalStyle .ant-modal-content {
    min-width: 280px;
    max-height: 500px;
    overflow: scroll;
  }
  .teaching-ModalStyle .ant-modal-content {
    min-width: 280px;
    max-height: 500px;
    overflow: scroll;
  }
  .fullBody-ModalStyle .ant-modal-content {
    min-width: 280px;
    max-height: 500px;
    overflow: scroll;
  }
}
