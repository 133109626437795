$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$btn-color: #ff1616;
.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 1px 1px 1px;
  width: 100%;

  :global(.ant-btn-primary) {
    margin-top: 8px;
    background: $btn-color;
    border-radius: 10px;
    font-family: $font-family-Extra-Light;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    border-color: $btn-color;
    width: 50%;
    margin-bottom: 20px;
    height: 40px;
    @media screen and (max-width: 650px) {
      width: 90%;
    }

    &:hover {
      background: $btn-color;
      color: #ffffff;
    }
  }
}

.activeFooterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 1px 20px 1px;
  width: 100%;

  :global(.ant-btn) {
    margin-top: 8px;
    pointer-events: none;
    background: #5858fe;
    border-radius: 10px;
    font-family: $font-family-Dark;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    width: 50%;
    margin-bottom: 20px;
    height: 40px;
    @media screen and (max-width: 650px) {
      width: 90%;
    }

    &:hover,
    &:focus,
    &:active {
      background: #5858fe;
      color: #ffffff;
    }
  }
}
