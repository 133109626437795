$font-family-Regular: 'Poppins-Regular';

.root {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
  width: 100%;
  font-family: $font-family-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232323;
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    outline: none;
    border: 1px solid #130f40;
  }
  :global(.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    text-align: left;
  }
  :global(.ant-select-arrow) {
    color: #1312dd;
    font-size: 15px;
  }
  :global(.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 7px;
  }
  &.inputVerticalWrapper {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left !important;
    }
  }

  .inputClass {
    width: 100%;
    // border: 1px solid #130f40;
    border-radius: 7px;
    box-shadow: none !important;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    color: #232323;
    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
      z-index: 1;
    }
  }

  .messCls {
    color: #232323;
    font-family: $font-family-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
  }
}
