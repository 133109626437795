$font-family: 'Poppins', sans-serif;
$font-family-Regular: 'Poppins-Regular';
$font-family-Light: 'Poppins-Light';
$font-family-Dark: 'Poppins-Dark';
$font-family-Medium: 'Poppins-Medium';
$font-style: normal;
$font-weight: 400;
$link-color: #1312dd;
$btn-color: #f6ef25;

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: -72px;
  }
  .formContainer {
    padding: 25px 15px;
    width: 618px;
    max-width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 4px 14px rgba(19, 15, 64, 0.4);
    @media (max-width: 1366px) {
     max-width: 80%;
    }
    @media (max-width: 650px) {
      width: 90%;
      height: 100%;
      padding: 15px 5px;
      max-width: 90%;
    }

    .linkClass {
      color: $link-color;
      font-family: $font-family-Regular;
      cursor: pointer;
    }

    .ForgotPassText {
      height: 12%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: $font-family-Regular;
      font-style: $font-style;
      font-weight: $font-weight;
      font-size: 40px;
      line-height: 60px;
      color: #130f40;
      .backContainer {
        padding-right: 20px;
        font-size: 20px;
        cursor: pointer;
        img{
          height: 23px;
        }
        @media (max-width: 1024px){
          img{
            height: 16px;
          }
        }
      }
      @media (max-width: 1366px) {
        font-size: 30px;
      }
      @media (max-width: 1024px) {
        margin-top: 20px;
        line-height: 40px;
      }
      @media (max-width: 650px) {
        font-size: 20px;
      }
      @media (max-width: 350px) {
        font-size: 18px;
      }
    }

    .formInput {
      width: 100%;
      margin-top: 20px;
      padding: 0 30px;
      display: flex;
      flex-direction: row;

      @media (max-width: 650px){
        padding: 0 10px;
        margin-top: 0;
      }

      .spanMessage {
        color: #232323;
        font-family: $font-family-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        opacity: 0.5;
        @media (max-width: 1024px){
          font-size: 12px;
        }
      }

      :global(.ant-form) {
        width: 100%;
      }
      :global(.ant-row) {
        flex-direction: column;
      }
      :global(.ant-form-item) {
        margin: 10px 1px 5px 1px;
      }

      :global(.ant-form-item-label) {
        :global(label) {
          font-size: 16px;
        }
      }

      :global(.ant-input-affix-wrapper) {
        border: 1px solid #130f40;
        border-radius: 10px;
      }

      :global(.ant-form-horizontal) {
        text-align: left;
      }

      :global(.ant-btn-primary) {
        margin-top: 20px;
        width: 100%;
        background: $btn-color;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #232323;
        border-color: $btn-color;
        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }
        &:hover {
          color: #1312dd;
          border-color: #1312dd;
        }
      }

      :global(.ant-input-suffix) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(168, 155, 155, 0.88);
      }
    }

    .passwordchange {
      align-items: center;
      width: 100%;
    }
    .messclass {
      color: #232323;
      font-family: $font-family;
      font-style: $font-style;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.resendOtpWrapper {
  font-size: 14px;
  display: flex;
  .headingWrapper {
    font-family: $font-family-Medium;
    color: #130f40;
  }
  span {
    color: #1312dd;
    cursor: pointer;
    margin-left: 5px;
  }
}
