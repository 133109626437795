$bg-img: url('../../../assets/images/HomePage/homeHeaderBg.jpg');
$responsiveBg-img: url('../../../assets/images/HomePage/responsiveHeader.jpg');
$responsiveBg-img1: url('../../../assets/images/HomePage/responsiveHeader1.jpg');
$font-family: 'Poppins', sans-serif;
$font-family-Dark: 'Poppins-Dark';
$font-family-Regular: 'Poppins-Regular';
$font-family-Medium: 'Poppins-Medium';
$font-family-Light: 'Poppins-Light';
$font-family-Extra-Light: 'Poppins-Extra-Light';
$font-style: normal;
$font-weight: 400;
$btn-color: #f6ef25;
$title-color: #130f40;
$text-color: #000000;

.headerContainer {
  min-height: 905px;
  background-image: $bg-img;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    background-image: $responsiveBg-img1;
    min-height: 616px;
  }
  @media (max-width: 1054px) {
    background-image: $responsiveBg-img1;
    min-height: 616px;
  }
  @media (max-width: 1024px){
    background-size: 100% 100%;
  }
  @media (max-width: 800px) {
    background-image: $responsiveBg-img1;
    min-height: 516px;
  }
  @media (max-width: 480px) {
    background-image: $responsiveBg-img;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 875px;
    margin-left: -2px;
  }

  .headerMain {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -55px;

    :global(.CogPlayIcon_cogPlayIcon__8uu20) {
      justify-content: flex-start;
      position: absolute;
      margin-top: -14px;
      margin-left: 320px;
      @media (max-width: 1280px) {
        // padding-top: 20px;
        margin-left: 220px;
      }
      @media (max-width: 1054px) {
        // padding-top: 20px;
        margin-left: 220px;
      }
      @media (max-width: 1024px) {
        margin-left: 180px;
      }
      @media (max-width: 992px){
        margin-top: -4px;
      }

      @media (max-width: 800px) {
        margin-left: 100px;
        font-size: 8px;
        font-family: $font-family-Regular;
        img {
          width: 80px;
        }
      }
      @media (max-width: 650px) {
        margin-left: 120px;
        font-size: 8px;
        font-family: $font-family-Regular;
        img {
          width: 80px;
        }
      }
      @media (max-width: 480px) {
        margin-left: 10px;
      }
    }

    .headingText {
      font-family: $font-family-Dark;
      font-style: normal;
      font-weight: $font-weight;
      font-size: 120px;
      color: #ffffff;
      margin-left: 320px;
      text-align: start;
      padding-top: 130px;

      line-height: 97.5%;
      max-width: 670px;
      @media (max-width: 1280px) {
        margin-left: 220px;
        font-size: 75px;
        line-height: 97.5%;
        max-width: 480px;
        padding-top: 90px;
      }
      @media (max-width: 1054px) {
        font-size: 75px;
        line-height: 97.5%;
        max-width: 480px;
        margin-left: 220px;
      }
      @media (max-width: 1024px){
        margin-left: 180px;
      }
      @media (max-width: 800px) {
        font-size: 50px;
        line-height: 97.5%;
        max-width: 300px;
        margin-left: 120px;
      }
      @media (max-width: 480px) {
        margin-left: 40px;
        font-size: 70px;
        line-height: 97.5%;
        max-width: 480px;
        padding-top: 310px;
      }
      @media (max-width: 425px) {
        font-size: 66px;
      }
      @media (max-width: 370px) {
        font-size: 55px;
      }
    }

    .headingSmallText {
      font-family: $font-family-Light;
      font-style: normal;
      font-weight: $font-weight;
      font-size: 22px;
      line-height: 33px;
      width: 65%;
      color: #ffffff;
      padding-top: 20px;
      @media (max-width: 1054px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 800px) {
        font-size: 10px;
        line-height: 10px;
      }
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 20px;
        width: 90%;
      }
    }

    .buttonClass {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      :global(.ant-btn-default) {
        margin-top: 10px;
        background: none;
        border: 1px solid #ffffff;
        border-radius: 50px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        width: 45%;
        height: 40px;
        @media screen and (max-width: 1280px) {
          width: 50%;
          font-size: 16px;
        }
        @media screen and (max-width: 1054px) {
          width: 50%;
          font-size: 15px;
        }
        @media screen and (max-width: 800px) {
          width: fit-content;
          font-size: 12px;
          height: 30px;
          line-height: 1;
        }

        &:hover {
          background: #ffffff;
          color: #130f40;
        }
      }
    }

    .becomeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66vw;
      @media (max-width: 768px){
        width: 100%;
      }

      :global(.ant-btn) {
        background: #f6ef25;
        border-radius: 100px;
        font-family: $font-family-Light;
        font-style: $font-style;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #232323;
        margin-top: 15px;
        width: 48%;
        height: 40px;
        @media (max-width: 768px){
          width: 100%;
        }
        @media (max-width: 480px){
          width: 60%;
        }
      }
      @media (max-width: 768px) {
        justify-content: center;
      }
    }
  }
}

.tabContainer {
  min-height: 67px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .tabText {
    display: flex;
    margin-right: 15%;
    padding: 20px 1px 5px 1px;
    z-index: 0;
    @media screen and (max-width: 992px) {
      display: none;
    }

    .loginTab {
      height: 5px;
      padding: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 30px;
      cursor: pointer;
    }

    .profileIcon {
      width: 22%;
      height: 5px;
      padding: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 30px;
      margin-left: -30px;
      :global(.anticon) {
        color: #ffffff;
      }
    }
    .logoutIcon {
      width: 16%;
      height: 5px;
      padding: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      margin-left: -20px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .manuContainer {
    display: none;
    z-index: 9999999;
    @media screen and (max-width: 992px) {
      display: flex;
      text-align: right;
      padding-right: 25px;
    }
  }
}

.drawerClass {
  z-index: 99999;

  :global(.ant-drawer-close) {
    color: red;
    font-size: 25px !important;
  }

  .hederContainer {
    text-align: center;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    margin-bottom: 40px;

    .cogPlayIcon {
      margin: auto;
      padding: auto;
      display: block;
    }
  }
}
