$font-family-Regular: 'Poppins-Regular';

.root {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
  width: 100%;
  font-family: $font-family-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232323;

  &.inputVerticalWrapper {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left !important;
    }
  }

  .inputClass {
    width: 100%;
    text-align: left;
    border: 1px solid #130f40;
    border-radius: 7px;
    box-shadow: none !important;
    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
      z-index: 1;
    }
  }

  .messCls {
    color: #232323;
    font-family: $font-family-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
  }
}
