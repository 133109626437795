body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* @font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat-bold';
  font-weight: bold;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
} */
@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Extra-Light';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Dark';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Extra-Dark';
  src: local('Poppins'), url(assets/Font/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Domine-Bold';
  src: local('Domine'), url(assets/Font/Domine/Domine-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Domine-Medium';
  src: local('Domine'), url(assets/Font/Domine/Domine-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Domine-Regular';
  src: local('Bitter'), url(assets/Font/Domine/Domine-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Domine-SemiBold';
  src: local('Domine'), url(assets/Font/Domine/Domine-SemiBold.ttf) format('truetype');
}
